import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { ICreateDealerProps } from '../Dealers.types';
import { createDealerReducer, initialState } from './CreateDealerModal.reducer';
import { CreateDealerAction } from './CreateDealerModal.actions';
import { createDealer } from './CreateDealerModal.service';

const CreateDealerModal = (props: ICreateDealerProps): JSX.Element => {
    const [state, dispatch] = React.useReducer(createDealerReducer, initialState);

    const updateDealerId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: CreateDealerAction.CHANGE_DEALER_ID, dealerId: e.target.value });
    };

    const updateDealerName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: CreateDealerAction.CHANGE_DEALER_NAME, dealerName: e.target.value });
    };

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: CreateDealerAction.SUBMIT_FORM });
        createDealer(state.dealerId, state.dealerName)
            .then((value: Response) => dispatch({ type: CreateDealerAction.RECEIVE_RESPONSE }))
            .then((value: void) => props.onHide())
            .catch((reason: any) => dispatch({ type: CreateDealerAction.RECEIVE_RESPONSE }));
        props.onHide();
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop='static'
            keyboard={false}
            >
            <Modal.Header closeButton={true}>
                <Modal.Title>Create Dealer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Stack gap={3}>
                            <Form.Group>
                                <Form.Label>Dealer Id</Form.Label>
                                <Form.Control
                                    id='createDealerIdInput'
                                    type='string'
                                    placeholder='Create Service Id'
                                    value={state.dealerId}
                                    onChange={updateDealerId}
                                    disabled={state.disabled}
                                    required={true}
                                    />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Dealer Name</Form.Label>
                                <Form.Control
                                    id='createDealerNameInput'
                                    type='string'
                                    placeholder='Create Service Name'
                                    value={state.dealerName}
                                    onChange={updateDealerName}
                                    disabled={state.disabled}
                                    required={true}
                                    />
                            </Form.Group>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                >
                                Create
                            </Button>
                        </Stack>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateDealerModal;