import ApiAccess from './ApiAccess';
import { ApiAccessAction, ApiAccessActionType, ApiAccessSearchChoice } from './ApiAccess.actions';
import { IApiAccess } from './ApiAccess.types';

export interface IApiAccessState {
    microserviceId: number,
    microserviceName: string,
    vendorId: string,
    vendorName: string,
    dealerId: string,
    dealerName: string,
    disabled: boolean,
    searchResult: IApiAccess[],
    searchType: ApiAccessSearchChoice,
    showCreateModal: boolean,
    showEditModal: boolean,
    editApiAccessId: number,
    createFlag: boolean,
    deleteFlag: boolean,
};

export const initialState: IApiAccessState = {
    microserviceId: 0,
    microserviceName: '',
    vendorId: '',
    vendorName: '',
    dealerId: '',
    dealerName: '',
    disabled: false,
    searchResult: [],
    searchType: ApiAccessSearchChoice.SEARCH_BY_MICROSERVICE_ID,
    showCreateModal: false,
    showEditModal: false,
    editApiAccessId: 0,
    createFlag: false,
    deleteFlag: false,
};

export const apiAccessReducer = (state: IApiAccessState, action: ApiAccessActionType): IApiAccessState => {
    switch (action.type) {
        case ApiAccessAction.CHANGE_MICROSERVICE_ID:
            return {
                ...state,
                microserviceId: action.microserviceId,
            };
        case ApiAccessAction.CHANGE_MICROSERVICE_NAME:
            return {
                ...state,
                microserviceName: action.microserviceName,
            };
        case ApiAccessAction.CHANGE_VENDOR_ID:
            return {
                ...state,
                vendorId: action.vendorId,
            };
        case ApiAccessAction.CHANGE_VENDOR_NAME:
            return {
                ...state,
                vendorName: action.vendorName,
            };
        case ApiAccessAction.CHANGE_DEALER_ID:
            return {
                ...state,
                dealerId: action.dealerId,
            };
        case ApiAccessAction.CHANGE_DEALER_NAME:
            return {
                ...state,
                dealerName: action.dealerName,
            };
        case ApiAccessAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case ApiAccessAction.RECEIVE_RESPONSE:
            return {
                ...state,
                disabled: false,
                searchResult: action.searchResult,
            };
        case ApiAccessAction.CHANGE_API_ACCESS_SEARCH_CHOICE:
            return {
                ...state,
                searchType: action.searchType,
            };
        case ApiAccessAction.EDIT_API_ACCESS:
            return {
                ...state,
                editApiAccessId: action.apiAccessId,
                showEditModal: true,
            };
        case ApiAccessAction.DELETE_API_ACCESS:
            return {
                ...state,
                deleteFlag: !state.deleteFlag,
            };
        case ApiAccessAction.CREATE_API_ACCESS:
            return {
                ...state,
                showCreateModal: true,
            };
        case ApiAccessAction.CLOSE_CREATE_MODAL:
            return {
                ...state,
                showCreateModal: false,
                createFlag: !state.createFlag,
            };
        case ApiAccessAction.CLOSE_EDIT_MODAL:
            return {
                ...state,
                showEditModal: false,
                editApiAccessId: 0,
            };
        default:
            throw new Error('Invalid action type.');
    }
};