const { REACT_APP_BASE_URL } = process.env;

export const createApiAccess = (microserviceId: number, vendorDealerLocationId: number, canRead: boolean, canWrite: boolean): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}apiaccess/createapiaccessrecord`, {
        method: 'POST',
        body: JSON.stringify({
            microserviceId: microserviceId,
            vendorDealerLocationId: vendorDealerLocationId,
            canRead: canRead,
            canWrite: canWrite,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};