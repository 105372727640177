const { REACT_APP_BASE_URL } = process.env;

export const getDealerLocationByDealerId = (dealerId: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}dealerlocations/getlocationsbydealerid?dealerId=${dealerId}`);
};

export const getDealerLocationByDealerName = (dealerName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}dealerlocations/searchlocationsbydealername?nameSubstring=${dealerName}`);
};

export const getAllDealerLocations = (): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}dealerlocations/getalldealerlocations`);
};

export const deleteDealerLocationById = (dealerLocationId: number): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}dealerlocations/deletedealerlocationbyid?dealerLocationId=${dealerLocationId}`, {
        method: 'DELETE',
    });
};
