import { UserPoolClientAction, UserPoolClientActionType } from './UserPoolClients.actions';
import { IUserPoolClient, IUserPoolClientDetail } from './UserPoolClients.types';

export interface IUserPoolState {
    userPoolId: string,
    clientId: string,
    disabled: boolean,
    searchResultLookup: IUserPoolClient[],
    searchResultDetail: IUserPoolClientDetail | undefined,
};

export const initialState: IUserPoolState = {
    userPoolId: '',
    clientId: '',
    disabled: false,
    searchResultLookup: [],
    searchResultDetail: undefined,
};

export const userPoolClientsReducer = (state: IUserPoolState, action: UserPoolClientActionType): IUserPoolState => {
    switch (action.type) {
        case UserPoolClientAction.UPDATE_USER_POOL_ID:
            return {
                ...state,
                userPoolId: action.userPoolId,
            };
        case UserPoolClientAction.UPDATE_CLIENT_ID:
            return {
                ...state,
                clientId: action.clientId,
            };
        case UserPoolClientAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case UserPoolClientAction.RECEIVE_RESPONSE_LOOKUP:
            return {
                ...state,
                disabled: false,
                searchResultLookup: action.searchResultLookup,
            };
        case UserPoolClientAction.RECEIVE_RESPONSE_DETAIL:
            return {
                ...state,
                disabled: false,
                searchResultDetail: action.searchResultDetail,  
            };
        default:
            throw new Error('Invalid action type.');
    }
};