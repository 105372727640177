import React from 'react';
import { UserPoolAction, UserPoolActionType } from "./UserPools.actions";
import { IUserPool } from "./UserPools.types";

const { REACT_APP_BASE_URL } = process.env;

export const getUserPools = (dispatch: React.Dispatch<UserPoolActionType>): void => {
    fetch(`${REACT_APP_BASE_URL}api/Aws/GetUserPools`)
        .then((value: Response) => value.json())
        .then((searchResult: IUserPool[]) => dispatch({ type: UserPoolAction.RECEIVE_RESPONSE, searchResult: searchResult }))
        .catch((reason: any) => dispatch({ type: UserPoolAction.RECEIVE_RESPONSE, searchResult: [] }));
};