import { UserPoolAction, UserPoolActionType } from './UserPools.actions';
import { IUserPool } from './UserPools.types';

export interface IUserPoolState {
    disabled: boolean,
    searchResult: IUserPool[],
};

export const initialState: IUserPoolState = {
    disabled: false,
    searchResult: [],
};

export const userPoolsReducer = (state: IUserPoolState, action: UserPoolActionType): IUserPoolState => {
    switch (action.type) {
        case UserPoolAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case UserPoolAction.RECEIVE_RESPONSE:
            return {
                ...state,
                disabled: false,
                searchResult: action.searchResult,
            };
        default:
            throw new Error('Invalid action type.');
    }
};