import { IApiAccess } from "./ApiAccess.types";

export enum ApiAccessAction {
    CHANGE_MICROSERVICE_ID = 'CHANGE_MICROSERVICE_ID',
    CHANGE_MICROSERVICE_NAME = 'CHANGE_MICROSERVICE_NAME',
    CHANGE_VENDOR_ID = 'CHANGE_VENDOR_ID',
    CHANGE_VENDOR_NAME = 'CHANGE_VENDOR_NAME',
    CHANGE_DEALER_ID = 'CHANGE_DEALER_ID',
    CHANGE_DEALER_NAME = 'CHANGE_DEALER_NAME',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_RESPONSE = 'RECEIVE_RESPONSE',
    CHANGE_API_ACCESS_SEARCH_CHOICE = 'CHANGE_API_ACCESS_SEARCH_CHOICE',
    EDIT_API_ACCESS = 'EDIT_API_ACCESS',
    DELETE_API_ACCESS = 'DELETE_API_ACCESS',
    CREATE_API_ACCESS = 'CREATE_API_ACCESS',
    CLOSE_CREATE_MODAL = 'CLOSE_CREATE_MODAL',
    CLOSE_EDIT_MODAL = 'CLOSE_EDIT_MODAL',
};

export enum ApiAccessSearchChoice {
    SEARCH_BY_MICROSERVICE_ID = 'SEARCH_BY_MICROSERVICE_ID',
    SEARCH_BY_MICROSERVICE_NAME = 'SEARCH_BY_MICROSERVICE_NAME',
    SEARCH_BY_VENDOR_ID = 'SEARCH_BY_VENDOR_ID',
    SEARCH_BY_VENDOR_NAME = 'SEARCH_BY_VENDOR_NAME',
    SEARCH_BY_DEALER_ID = 'SEARCH_BY_DEALER_ID',
    SEARCH_BY_DEALER_NAME = 'SEARCH_BY_DEALER_NAME',
};

export type ApiAccessActionType = IChangeMicroserviceIdAction
    | IChangeMicroserviceNameAction
    | IChangeVendorIdAction
    | IChangeVendorNameAction
    | IChangeDealerIdAction
    | IChangeDealerNameAction
    | ISubmitFormAction
    | IReceiveResponseAction
    | IChangeDealerLocationSearchAction
    | IEditApiAccessAction
    | IDeleteApiAccessAction
    | ICreateApiAccessAction
    | ICloseCreateModalAction
    | ICloseEditModalAction;

export interface IChangeMicroserviceIdAction {
    type: ApiAccessAction.CHANGE_MICROSERVICE_ID,
    microserviceId: number,
};

export interface IChangeMicroserviceNameAction {
    type: ApiAccessAction.CHANGE_MICROSERVICE_NAME,
    microserviceName: string,
};

export interface IChangeVendorIdAction {
    type: ApiAccessAction.CHANGE_VENDOR_ID,
    vendorId: string,
};

export interface IChangeVendorNameAction {
    type: ApiAccessAction.CHANGE_VENDOR_NAME,
    vendorName: string,
};
export interface IChangeDealerIdAction {
    type: ApiAccessAction.CHANGE_DEALER_ID,
    dealerId: string,
};

export interface IChangeDealerNameAction {
    type: ApiAccessAction.CHANGE_DEALER_NAME,
    dealerName: string,
};

export interface ISubmitFormAction {
    type: ApiAccessAction.SUBMIT_FORM,
};

export interface IReceiveResponseAction {
    type: ApiAccessAction.RECEIVE_RESPONSE,
    searchResult: IApiAccess[],
};

export interface IChangeDealerLocationSearchAction {
    type: ApiAccessAction.CHANGE_API_ACCESS_SEARCH_CHOICE,
    searchType: ApiAccessSearchChoice,
};

export interface IEditApiAccessAction {
    type: ApiAccessAction.EDIT_API_ACCESS,
    apiAccessId: number,
};

export interface IDeleteApiAccessAction {
    type: ApiAccessAction.DELETE_API_ACCESS,
};

export interface ICreateApiAccessAction {
    type: ApiAccessAction.CREATE_API_ACCESS,
};

export interface ICloseCreateModalAction {
    type: ApiAccessAction.CLOSE_CREATE_MODAL,
};

export interface ICloseEditModalAction {
    type: ApiAccessAction.CLOSE_EDIT_MODAL,
};
