import { Outlet } from "react-router-dom";
import Stack from 'react-bootstrap/Stack';

const UserPoolClients = (): JSX.Element => {
    return(
        <Stack gap={3}>
            <h1>User Pool Clients</h1>
            <Outlet />
        </Stack>
    );
};

export default UserPoolClients;