import { IUserPool } from "./UserPools.types";

export enum UserPoolAction {
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_RESPONSE = 'RECEIVE_RESPONSE',
};

export type UserPoolActionType = ISubmitFormAction
    | IReceiveResponseAction;

export interface ISubmitFormAction {
    type: UserPoolAction.SUBMIT_FORM,
};

export interface IReceiveResponseAction {
    type: UserPoolAction.RECEIVE_RESPONSE,
    searchResult: IUserPool[],
};