import ThemeProvider from 'react-bootstrap/ThemeProvider';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Outlet } from "react-router-dom";
import { withAuthenticator, WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsConfig from './AwsConfig';

import './App.css';
import '@aws-amplify/ui-react/styles.css';
import Button from 'react-bootstrap/esm/Button';

Amplify.configure(awsConfig);

const { REACT_APP_ENV_NAME } = process.env;

function App(props: WithAuthenticatorProps) {
  const getEnvironment = (): string => {
    switch (REACT_APP_ENV_NAME) {
      case 'PROD':
        return 'Production';
      case 'STAGING':
        return 'Staging';
      default:
        return 'Development';
    }
  }
  
  return (
    <ThemeProvider>
      <Navbar bg='dark' variant='dark' expand='lg'>
        <Container>
          <Navbar.Brand href='/home'>DealerBuilt API Access Management - {getEnvironment()}</Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto'>
              <NavDropdown title='Dealer & Vendor Management' id='dealer-vendor-management'>
                <NavDropdown.Item href='/dealer'>Dealers</NavDropdown.Item>
                <NavDropdown.Item href='/location'>Locations</NavDropdown.Item>
                <NavDropdown.Item href='/vendor'>Vendors</NavDropdown.Item>
                <NavDropdown.Item href='/association'>Associations</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href='/microservice'>API Services</NavDropdown.Item>
                <NavDropdown.Item href='/access'>API Access</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title='AWS Cognito Management' id='aws-cognito-management'>
                <NavDropdown.Item href='/userpools'>User Pools</NavDropdown.Item>
                <NavDropdown.Item href='/userpoolclients/lookup'>User Pool Client Lookup</NavDropdown.Item>
                <NavDropdown.Item href='/userpoolclients/details'>User Pool Client Details</NavDropdown.Item>
                <NavDropdown.Item href='/userpoolresourceservers'>User Pool Resource Servers</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href='/clientscopes'>Client Scopes</NavDropdown.Item>
              </NavDropdown>
              {props.user && <Button onClick={props.signOut}>Sign out</Button>}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container>
        <Outlet />
      </Container>
    </ThemeProvider>
  );
}

export default withAuthenticator(App);
