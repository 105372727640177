import { IMicroservice } from "../../Microservices/Microservices.types";
import { IVendorDealerLocation } from "../../VendorDealerLocations";
import { CreateApiAccessAction, CreateApiAccessActionType } from "./CreateApiAccessModal.actions";

export interface ICreateApiAcessState {
    microserviceOptions: IMicroservice[],
    vendorDealerLocationOptions: IVendorDealerLocation[],
    disabled: boolean,
    selectedMicroserviceId: number,
    selectedVendorDealerLocationId: number,
    canRead: boolean,
    canWrite: boolean,
};

export const initialState: ICreateApiAcessState = {
    microserviceOptions: [],
    vendorDealerLocationOptions: [],
    disabled: false,
    selectedMicroserviceId: 0,
    selectedVendorDealerLocationId: 0,
    canRead: false,
    canWrite: false,
};

export const createApiAcessReducer = (state: ICreateApiAcessState, action: CreateApiAccessActionType): ICreateApiAcessState => {
    switch (action.type) {
        case CreateApiAccessAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case CreateApiAccessAction.RECEIVE_RESPONSE:
            return {
                ...state,
                disabled: false,
            };
        case CreateApiAccessAction.RECEIVE_MICROSERVICES_RESPONSE:
            return {
                ...state,
                microserviceOptions: action.microserviceOptions,
                selectedMicroserviceId: (action.microserviceOptions && action.microserviceOptions[0] && action.microserviceOptions[0].id) || 0,
            };
        case CreateApiAccessAction.RECEIVE_VENDOR_DEALER_LOCATIONS_RESPONSE:
            return {
                ...state,
                vendorDealerLocationOptions: action.vendorDealerLocationOptions,
                selectedVendorDealerLocationId: (action.vendorDealerLocationOptions && action.vendorDealerLocationOptions[0] && action.vendorDealerLocationOptions[0].id) || 0,
            };
        case CreateApiAccessAction.SELECT_MICROSERVICE:
            return {
                ...state,
                selectedMicroserviceId: action.selectedMicroserviceId,
            };
        case CreateApiAccessAction.SELECT_VENDOR_DEALER_LOCATION:
            return {
                ...state,
                selectedVendorDealerLocationId: action.selectedVendorDealerLocationId,
            };
        case CreateApiAccessAction.UPDATE_CAN_READ:
            return {
                ...state,
                canRead: !state.canRead,
            };
        case CreateApiAccessAction.UPDATE_CAN_WRITE:
            return {
                ...state,
                canWrite: !state.canWrite,
            };
        default:
            throw new Error("Invalid Action type.");
    }
};
