import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { IVendor } from './Vendors.types';
import { getVendorById, getVendorByName, getAllVendors, deleteVendorById } from './Vendors.service';
import { VendorAction, VendorSearchChoice } from './Vendors.actions';
import { vendorReducer, initialState } from './Vendors.reducer';
import VendorsDataTable from './DataTable';
import CreateVendorModal from './Create';
import EditVendorModal from './Edit';

const Vendors = (): JSX.Element => {
    const [state, dispatch] = React.useReducer(vendorReducer, initialState);

    React.useEffect(() => {
        _getAllVendors();
    }, []);

    React.useEffect(() => {
        _getAllVendors();
    }, [state.showEditModal, state.deleteFlag, state.createFlag])

    const _getAllVendors = (): void => {
        getAllVendors()
            .then((value: Response) => value.json())
            .then((searchResult: IVendor[]) => dispatch({ type: VendorAction.RECEIVE_RESPONSE, searchResult: searchResult }))
            .catch((reason: any) => dispatch({ type: VendorAction.RECEIVE_RESPONSE, searchResult: [] }));
    };

    const updateVendorId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: VendorAction.CHANGE_VENDOR_ID, vendorId: e.target.value });
    };

    const updateVendorName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: VendorAction.CHANGE_VENDOR_NAME, vendorName: e.target.value });
    };

    const updateSearchBy = (choice: VendorSearchChoice): void => {
        dispatch({ type: VendorAction.CHANGE_VENDOR_SEARCH_CHOICE, searchType: choice})
    }

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: VendorAction.SUBMIT_FORM });
        switch (state.searchType) {
            case VendorSearchChoice.SEARCH_BY_ID:
                getVendorById(state.vendorId)
                    .then((value: Response) => value.json())
                    .then((searchResult: IVendor) => dispatch({ type: VendorAction.RECEIVE_RESPONSE, searchResult: [searchResult] }))
                    .catch((reason: any) => dispatch({ type: VendorAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            case VendorSearchChoice.SEARCH_BY_NAME:
                getVendorByName(state.vendorName)
                    .then((value: Response) => value.json())
                    .then((searchResult: IVendor[]) => dispatch({ type: VendorAction.RECEIVE_RESPONSE, searchResult: searchResult }))
                    .catch((reason: any) => dispatch({ type: VendorAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            default:
                throw new Error('Invalid search choice.');
        }
    };

    const resetForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        _getAllVendors();
    };

    const onEditVendor = (vendorPrimaryKeyId: number): void => {
        dispatch({ type: VendorAction.EDIT_VENDOR, vendorPrimaryKeyId: vendorPrimaryKeyId });
    };

    const onDeleteVendor = (vendorPrimaryKeyId: number): void => {
        deleteVendorById(vendorPrimaryKeyId)
            .then((value: Response) => dispatch({ type: VendorAction.DELETE_VENDOR }))
            .catch((reason: any) => dispatch({ type: VendorAction.DELETE_VENDOR }));
    };

    const onHideCreateModal = (): void => {
        dispatch({ type: VendorAction.CLOSE_CREATE_MODAL });
    };

    const onHideEditModal = (): void => {
        dispatch({ type: VendorAction.CLOSE_EDIT_MODAL });
    };

    const _createVendor = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: VendorAction.CREATE_VENDOR });
    }

    return (
        <Stack gap={3}>
            <h1>Vendors</h1>
            <Form>
                <Stack gap={3}>
                    <Form.Group>
                        <Form.Check
                            id='vendorSearchByIdRadio'
                            type='radio'
                            name='searchBy'
                            label={`Search By ID`}
                            onChange={_ => updateSearchBy(VendorSearchChoice.SEARCH_BY_ID)}
                            checked={state.searchType === VendorSearchChoice.SEARCH_BY_ID}
                            inline={true}
                            />
                        <Form.Check
                            id='vendorSearchByNameRadio'
                            type='radio'
                            name='searchBy'
                            label={`Search By Name`}
                            onChange={_ => updateSearchBy(VendorSearchChoice.SEARCH_BY_NAME)}
                            checked={state.searchType === VendorSearchChoice.SEARCH_BY_NAME}
                            inline={true}
                            />
                    </Form.Group>
                    {state.searchType === VendorSearchChoice.SEARCH_BY_ID && (
                        <Form.Group>
                            <Form.Label>Vendor ID</Form.Label>
                            <Form.Control
                                id='vendorIdInput'
                                type='string'
                                placeholder='Enter Vendor ID'
                                value={state.vendorId}
                                onChange={updateVendorId}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    {state.searchType === VendorSearchChoice.SEARCH_BY_NAME && (
                        <Form.Group>
                            <Form.Label>Vendor Name</Form.Label>
                            <Form.Control
                                id='vendorNameInput'
                                type='string'
                                placeholder='Enter Vendor Name'
                                value={state.vendorName}
                                onChange={updateVendorName}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Stack gap={3} direction='horizontal'>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                disabled={state.disabled}
                                >
                                Search
                            </Button>
                            <Button
                                variant='secondary'
                                onClick={resetForm}
                                >
                                Reset
                            </Button>
                            <Button
                                variant='success'
                                onClick={_createVendor}
                                >
                                Create New Vendor
                            </Button>
                        </Stack>
                    </Form.Group>
                </Stack>
            </Form>
            <VendorsDataTable
                data={state.searchResult}
                onEdit={onEditVendor}
                onDelete={onDeleteVendor}
                />
            <CreateVendorModal
                show={state.showCreateModal}
                onHide={onHideCreateModal}
                />
            <EditVendorModal
                show={state.showEditModal}
                vendorPrimaryKeyId={state.editVendorPrimaryKeyId}
                onHide={onHideEditModal}
                />
        </Stack>
    );
};

export default Vendors;