const {
    REACT_APP_AWS_REGION,
    REACT_APP_AWS_USER_POOL_ID,
    REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    REACT_APP_AWS_OAUTH_DOMAIN,
    REACT_APP_AWS_OAUTH_REDIRECT_SIGN_IN,
    REACT_APP_AWS_OAUTH_REDIRECT_SIGN_OUT
} = process.env;

// DM 01/06/2023 Documentation: https://docs.amplify.aws/lib/auth/start/q/platform/js/#re-use-existing-authentication-resource
const awsConfig: any = {
    Auth: {
        region: REACT_APP_AWS_REGION,
        userPoolId: REACT_APP_AWS_USER_POOL_ID,
        userPoolWebClientId: REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
        mandatorySignIn: true,
        authenticationFlowType: 'USER_SRP_AUTH',
        oauth: {
            domain: REACT_APP_AWS_OAUTH_DOMAIN,
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: REACT_APP_AWS_OAUTH_REDIRECT_SIGN_IN,
            redirectSignOut: REACT_APP_AWS_OAUTH_REDIRECT_SIGN_OUT,
            responseType: 'code'
        }
    }
};

export default awsConfig;