import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { IDealerLocation, IDealerLocationsDataTableProps } from '../DealerLocations.types';

const DealerLocationsDataTable = (props: IDealerLocationsDataTableProps): JSX.Element => {
    return (
        <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Location Id</th>
                        <th>Location Name</th>
                        <th>Dealer Id</th>
                        <th>Dealer Name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {props.data && props.data.map((value: IDealerLocation, index: number) => {
                        return (
                            <tr key={`row-${index}`}>
                                <td key={`dealerLocation-location-id-${index}`}>{value.locationId}</td>
                                <td key={`dealerLocation-name-${index}`}>{value.locationName}</td>
                                <td key={`dealerLocation-dealer-id-${index}`}>{value.dealerId}</td>
                                <td key={`dealerLocation-dealer-name-${index}`}>{value.dealerName}</td>
                                <td key={`dealerLocation-editCol-${index}`}>
                                    <Button
                                        variant='secondary'
                                        onClick={_ => props.onEdit(value.id)}
                                        >
                                        Edit
                                    </Button>
                                </td>
                                <td key={`dealerLocation-deleteCol-${index}`}>
                                    <Button
                                        variant='danger'
                                        onClick={_ => props.onDelete(value.id)}
                                        >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
    );
};

export default DealerLocationsDataTable;
