const { REACT_APP_BASE_URL } = process.env;

export const getDealerLocationById = (dealerLocationPrimaryKeyId: number): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}dealerlocations/getdealerlocationbykey?dealerLocationId=${dealerLocationPrimaryKeyId}`);
};

export const updateDealerLocationName = (dealerLocationPrimaryKeyId: number, dealerLocationName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}dealerlocations/updatedealerlocation?dealerLocationId=${dealerLocationPrimaryKeyId}`, {
        method: 'PATCH',
        body: JSON.stringify({
            dealerLocationName: dealerLocationName,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
}
