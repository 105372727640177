import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { MicroserviceAction } from './Microservices.actions';
import { initialState, microserviceReducer } from './Microservices.reducer';
import { searchMicroserviceByName, getAllMicroservices, deleteMicroserviceById } from './Microservices.service';
import MicroservicesDataTable from './DataTable';
import EditMicroserviceModal from './Edit';
import CreateMicroserviceModal from './Create';
import { IMicroservice } from './Microservices.types';

const Microservices = (): JSX.Element => {
    const [state, dispatch] = React.useReducer(microserviceReducer, initialState);

    React.useEffect(() => {
        _getAllMicroservices();
    }, []);

    React.useEffect(() => {
        _getAllMicroservices();
    }, [state.showEditModal, state.deleteFlag, state.createFlag]);

    const _getAllMicroservices = (): void => {
        getAllMicroservices()
            .then((value: Response) => value.json())
            .then((searchResult:  IMicroservice[]) => dispatch({ type: MicroserviceAction.RECEIVE_GET_RESPONSE, searchResult: searchResult }))
            .catch((reason: any) => dispatch({ type: MicroserviceAction.RECEIVE_GET_RESPONSE, searchResult: [] }));
    };

    const updateMicroserviceName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: MicroserviceAction.CHANGE_MICROSERVICE_NAME, microserviceName: e.target.value });
    };

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: MicroserviceAction.SUBMIT_FORM });

        searchMicroserviceByName(state.microserviceName)
            .then((value: Response) => value.json())
            .then((searchResult: IMicroservice[]) => dispatch({ type: MicroserviceAction.RECEIVE_GET_RESPONSE, searchResult: searchResult }))
            .catch((reason: any) => dispatch({ type: MicroserviceAction.RECEIVE_GET_RESPONSE, searchResult: [] }));
    };

    const resetForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        _getAllMicroservices();
    };

    const _createMicroservice = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: MicroserviceAction.CREATE_MICROSERVICE });
    }

    const onEditMicroservice = (microserviceId: number): void => {
        dispatch({ type: MicroserviceAction.EDIT_MICROSERVICE, microserviceId: microserviceId });
    };

    const onDeleteMicroservice = (microserviceId: number): void => {
        deleteMicroserviceById(microserviceId)
            .then((value: Response) => dispatch({ type: MicroserviceAction.DELETE_MICROSERVICE }))
            .catch((reason: any) => dispatch({ type: MicroserviceAction.DELETE_MICROSERVICE }));
    };

    const onHideCreateModal = (): void => {
        dispatch({ type: MicroserviceAction.CLOSE_CREATE_MODAL });
    }

    const onHideEditModal = (): void => {
        dispatch({ type: MicroserviceAction.CLOSE_EDIT_MODAL });
    };

    return (
        <Stack gap={3}>
            <h1>API Services Lookup</h1>
            <Form>
                <Stack gap={3}>
                    <Form.Group>
                        <Form.Label>API Name</Form.Label>
                        <Form.Control
                            id='microserviceNameInput'
                            type='string'
                            placeholder='Enter Service Name'
                            value={state.microserviceName}
                            onChange={updateMicroserviceName}
                            disabled={state.disabled}
                            />
                    </Form.Group>
                    <Form.Group>
                        <Stack gap={3} direction='horizontal'>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                disabled={state.disabled}
                                >
                                Search
                            </Button>
                            <Button
                                variant='secondary'
                                onClick={resetForm}
                                >
                                Reset
                            </Button>
                            <Button
                                variant='success'
                                onClick={_createMicroservice}
                                >
                                Create New Service
                            </Button>
                        </Stack>
                    </Form.Group>
                </Stack>
            </Form>
            <MicroservicesDataTable
                data={state.searchResult}
                onEdit={onEditMicroservice}
                onDelete={onDeleteMicroservice}
                />
            <CreateMicroserviceModal
                show={state.showCreateModal}
                onHide={onHideCreateModal}
                />
            <EditMicroserviceModal
                show={state.showEditModal}
                microserviceId={state.editMicroserviceId}
                onHide={onHideEditModal}
                />
        </Stack>
    );
};

export default Microservices;