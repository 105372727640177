import { VendorAction, VendorActionType, VendorSearchChoice } from './Vendors.actions';
import { IVendor } from './Vendors.types';

export interface IVendorState {
    vendorId: string,
    vendorName: string,
    disabled: boolean,
    searchResult: IVendor[],
    searchType: VendorSearchChoice,
    showCreateModal: boolean,
    showEditModal: boolean,
    editVendorPrimaryKeyId: number,
    createFlag: boolean,
    deleteFlag: boolean,
};

export const initialState: IVendorState = {
    vendorId: '',
    vendorName: '',
    disabled: false,
    searchResult: [],
    searchType: VendorSearchChoice.SEARCH_BY_ID,
    showCreateModal: false,
    showEditModal: false,
    editVendorPrimaryKeyId: 0,
    createFlag: false,
    deleteFlag: false,
};

export const vendorReducer = (state: IVendorState, action: VendorActionType): IVendorState => {
    switch (action.type) {
        case VendorAction.CHANGE_VENDOR_ID:
            return {
                ...state,
                vendorId: action.vendorId,
            };
        case VendorAction.CHANGE_VENDOR_NAME:
            return {
                ...state,
                vendorName: action.vendorName,
            };
        case VendorAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case VendorAction.RECEIVE_RESPONSE:
            return {
                ...state,
                disabled: false,
                searchResult: action.searchResult,
            };
        case VendorAction.CHANGE_VENDOR_SEARCH_CHOICE:
            return {
                ...state,
                searchType: action.searchType,
            };
            case VendorAction.CREATE_VENDOR:
                return {
                    ...state,
                    showCreateModal: true,
                };
            case VendorAction.EDIT_VENDOR:
                return {
                    ...state,
                    showEditModal: true,
                    editVendorPrimaryKeyId: action.vendorPrimaryKeyId,
                };
            case VendorAction.DELETE_VENDOR:
                return {
                    ...state,
                    deleteFlag: !state.deleteFlag,
                };
            case VendorAction.CLOSE_CREATE_MODAL:
                return {
                    ...state,
                    showCreateModal: false,
                    createFlag: !state.createFlag,
                };
            case VendorAction.CLOSE_EDIT_MODAL:
                return {
                    ...state,
                    showEditModal: false,
                    editVendorPrimaryKeyId: 0,
                };
        default:
            throw new Error('Invalid action type.');
    }
};