const { REACT_APP_BASE_URL } = process.env;

export const getVendorById = (vendorId: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}vendors/getvendorbyid?vendorId=${vendorId}`);
};

export const getVendorByName = (vendorName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}vendors/getvendorbyname?nameSubstring=${vendorName}`);
};

export const getAllVendors = (): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}vendors/getallvendors`);
};

export const deleteVendorById = (vendorId: number): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}vendors/deletevendorbyid?vendorId=${vendorId}`, {
        method: 'DELETE',
    });
};
