import { IDealerLocation } from "./DealerLocations.types";

export enum DealerLocationAction {
    CHANGE_DEALER_ID = 'CHANGE_DEALER_LOCATION_ID',
    CHANGE_DEALER_NAME = 'CHANGE_DEALER_LOCATION_NAME',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_RESPONSE = 'RECEIVE_RESPONSE',
    CHANGE_DEALER_LOCATION_SEARCH_CHOICE = 'CHANGE_DEALER_LOCATION_SEARCH_CHOICE',
    CREATE_DEALER_LOCATION = 'CREATE_DEALER_LOCATION',
    EDIT_DEALER_LOCATION = 'EDIT_DEALER_LOCATION',
    DELETE_DEALER_LOCATION = 'DELETE_DEALER_LOCATION',
    CLOSE_CREATE_MODAL = 'CLOSE_CREATE_MODAL',
    CLOSE_EDIT_MODAL = 'CLOSE_EDIT_MODAL',
    SAVE_EDIT = 'SAVE_EDIT',
};

export enum DealerLocationSearchChoice {
    SEARCH_BY_DEALER_ID = 'SEARCH_BY_DEALER_ID',
    SEARCH_BY_DEALER_NAME = 'SEARCH_BY_DEALER_NAME',
};

export type DealerLocationActionType = IChangeDealerIdAction
    | IChangeDealerNameAction
    | ISubmitFormAction
    | IReceiveResponseAction
    | IChangeDealerLocationSearchAction
    | ICreateDealerLocationAction
    | IEditDealerLocationAction
    | IDeleteDealerLocationAction
    | ICloseCreateModalAction
    | ICloseEditModalAction
    | ISaveEditAction;

export interface IChangeDealerIdAction {
    type: DealerLocationAction.CHANGE_DEALER_ID,
    dealerId: string,
};

export interface IChangeDealerNameAction {
    type: DealerLocationAction.CHANGE_DEALER_NAME,
    dealerName: string,
};

export interface ISubmitFormAction {
    type: DealerLocationAction.SUBMIT_FORM,
};

export interface IReceiveResponseAction {
    type: DealerLocationAction.RECEIVE_RESPONSE,
    searchResult: IDealerLocation[],
};

export interface IChangeDealerLocationSearchAction {
    type: DealerLocationAction.CHANGE_DEALER_LOCATION_SEARCH_CHOICE,
    searchType: DealerLocationSearchChoice,
};

export interface ICreateDealerLocationAction {
    type: DealerLocationAction.CREATE_DEALER_LOCATION,
};

export interface IEditDealerLocationAction {
    type: DealerLocationAction.EDIT_DEALER_LOCATION,
    dealerLocationId: number
};

export interface IDeleteDealerLocationAction {
    type: DealerLocationAction.DELETE_DEALER_LOCATION,
};

export interface ICloseCreateModalAction {
    type: DealerLocationAction.CLOSE_CREATE_MODAL,
};

export interface ICloseEditModalAction {
    type: DealerLocationAction.CLOSE_EDIT_MODAL,
};

export interface ISaveEditAction {
    type: DealerLocationAction.SAVE_EDIT,
};
