import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { getApiAccessById, updateApiAccess } from './EditApiAccessModal.service';
import { IApiAccess, IEditApiAccessProps } from '../ApiAccess.types';
import { editApiAccessReducer, initialState } from './EditApiAccessModal.reducer';
import { EditApiAccessAction } from './EditApiAccessModal.actions';

const EditApiAccessModal = (props: IEditApiAccessProps): JSX.Element => {
    const [state, dispatch] = React.useReducer(editApiAccessReducer, initialState);

    React.useEffect(() => {
        if (props.apiAccessId === 0) {
            return;
        }

        getApiAccessById(props.apiAccessId)
            .then((value: Response) => value.json())
            .then((apiAccess: IApiAccess) => dispatch({ type: EditApiAccessAction.RECEIVE_GET_RESPONSE, searchResult: apiAccess }))
            .catch((reason: any) => dispatch({ type: EditApiAccessAction.RECEIVE_GET_RESPONSE, searchResult: undefined }));
    }, [props.apiAccessId]);

    const updateCanRead = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: EditApiAccessAction.CHANGE_CAN_READ, canRead: e.currentTarget.checked });
    };

    const updateCanWrite = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: EditApiAccessAction.CHANGE_CAN_WRITE, canWrite: e.currentTarget.checked });
    }

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: EditApiAccessAction.SUBMIT_FORM });

        updateApiAccess(props.apiAccessId, state.canRead, state.canWrite)
            .then((value: Response) => dispatch({ type: EditApiAccessAction.RECEIVE_PATCH_RESPONSE }))
            .then((value: void) => props.onHide())
            .catch((reason: any) => dispatch({ type: EditApiAccessAction.RECEIVE_PATCH_RESPONSE }));
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop='static'
            keyboard={false}
            >
            <Modal.Header closeButton={true}>
                <Modal.Title>Edit Dealer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Stack gap={3}>
                        <Form.Group>
                            <Form.Label>Id</Form.Label>
                            <Form.Control
                                id='editDealerIdReadOnlyInput'
                                type='number'
                                value={props.apiAccessId}
                                disabled={true}
                                />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Microservice Name</Form.Label>
                            <Form.Control
                                type='string'
                                value={state.microserviceName}
                                disabled={true}
                                />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Association</Form.Label>
                            <Form.Control
                                type='string'
                                value={state.vendorDealerLocationName}
                                disabled={true}
                                />
                        </Form.Group>
                            <Form.Group>
                                <Form.Label>Can Read</Form.Label>
                                <Form.Check
                                    type='checkbox'
                                    id='editApiAccessCanReadCheckbox'
                                    onChange={updateCanRead}
                                    checked={state.canRead}
                                    />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Can Write</Form.Label>
                                <Form.Check
                                    type='checkbox'
                                    id='editApiAccessCanWriteCheckbox'
                                    onChange={updateCanWrite}
                                    checked={state.canWrite}
                                    />
                            </Form.Group>
                        <Form.Group>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                >
                                Edit
                            </Button>
                        </Form.Group>
                        </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditApiAccessModal;