const { REACT_APP_BASE_URL } = process.env;

export const getVendorByKey = (vendorId: number): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}vendors/getvendorbykey?vendorId=${vendorId}`);
};

export const updateVendorName = (vendorPrimaryKeyId: number, vendorId: string, vendorName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}vendors/updatevendor?vendorPrimaryKeyId=${vendorPrimaryKeyId}`, {
        method: 'PATCH',
        body: JSON.stringify({
            vendorId: vendorId,
            vendorName: vendorName,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
}
