import { IDealerLocation } from "../DealerLocations.types";

export enum EditDealerLocationAction {
    CHANGE_DEALER_LOCATION_NAME = 'CHANGE_DEALER_LOCATION_NAME',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_GET_RESPONSE = 'RECEIVE_GET_RESPONSE',
    RECEIVE_PATCH_RESPONSE = 'RECEIVE_PATCH_RESPONSE',
};

export type EditDealerLocationActionType = IChangeDealerLocationNameAction
    | ISubmitFormAction
    | IReceiveGetResponseAction
    | IReceivePatchResponseAction;

export interface IChangeDealerLocationNameAction {
    type: EditDealerLocationAction.CHANGE_DEALER_LOCATION_NAME,
    dealerLocationName: string,
};

export interface ISubmitFormAction {
    type: EditDealerLocationAction.SUBMIT_FORM,
};

export interface IReceiveGetResponseAction {
    type: EditDealerLocationAction.RECEIVE_GET_RESPONSE,
    searchResult: IDealerLocation | undefined,
};

export interface IReceivePatchResponseAction {
    type: EditDealerLocationAction.RECEIVE_PATCH_RESPONSE,
}
