import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { IVendorDealerLocation, IVendorDealerLocationDataTableProps } from "../VendorDealerLocations.types";

const VendorDealerLocationDataTable = (props: IVendorDealerLocationDataTableProps): JSX.Element => {
    return(
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Vendor Name</th>
                    <th>Dealer Name</th>
                    <th>Location Name</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.data && props.data.map((value: IVendorDealerLocation, index: number) => {
                    return (
                        <tr key={`row-${index}`}>
                            <td key={`vendorDealerLocation-id-${index}`}>{value.id}</td>
                            <td key={`vendorDealerLocation-vendorName-${index}`}>{value.vendorName}</td>
                            <td key={`vendorDealerLocation-DealerName-${index}`}>{value.dealerName}</td>
                            <td key={`vendorDealerLocation-locationName-${index}`}>{value.locationName}</td>
                            <td key={`vendorDealerLocation-deleteCol-${index}`}>
                                <Button
                                    variant='danger'
                                    onClick={_ => props.onDelete(value.id)}
                                    >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default VendorDealerLocationDataTable;