const { REACT_APP_BASE_URL } = process.env;

export const createDealerLocation = (dealerPrimaryKeyId: number, locationId: number, dealerLocationName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}dealerlocations/createdealerlocation`, {
        method: 'POST',
        body: JSON.stringify({
            dealerPrimaryKeyId: dealerPrimaryKeyId,
            locationId: locationId,
            locationName: dealerLocationName,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
