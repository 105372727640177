import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { DealerLocationAction, DealerLocationSearchChoice } from './DealerLocations.actions';
import { dealerLocationReducer, initialState } from './DealerLocations.reducer';
import { IDealerLocation } from './DealerLocations.types';
import { getDealerLocationByDealerId, getDealerLocationByDealerName, getAllDealerLocations, deleteDealerLocationById } from './DealerLocations.service';
import DealerLocationsDataTable from './DataTable';
import EditDealerLocationModal from './Edit';
import CreateDealerLocationModal from './Create';

const DealerLocations = (): JSX.Element => {
    const [state, dispatch] = React.useReducer(dealerLocationReducer, initialState);

    React.useEffect(() => {
        _getAllDealerLocations();
    }, []);

    React.useEffect(() => {
        _getAllDealerLocations();
    }, [state.createFlag, state.deleteFlag, state.editDealerLocationId]);

    const _getAllDealerLocations = (): void => {
        getAllDealerLocations()
            .then((value: Response) => value.json())
            .then((searchResult: IDealerLocation[]) => dispatch({ type: DealerLocationAction.RECEIVE_RESPONSE, searchResult: searchResult }));
    }

    const updateDealerId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: DealerLocationAction.CHANGE_DEALER_ID, dealerId: e.target.value });
    };

    const updateDealerName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: DealerLocationAction.CHANGE_DEALER_NAME, dealerName: e.target.value });
    };

    const updateSearchBy = (choice: DealerLocationSearchChoice): void => {
        dispatch({ type: DealerLocationAction.CHANGE_DEALER_LOCATION_SEARCH_CHOICE, searchType: choice})
    }

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: DealerLocationAction.SUBMIT_FORM });
        switch (state.searchType) {
            case DealerLocationSearchChoice.SEARCH_BY_DEALER_ID:
                getDealerLocationByDealerId(state.dealerId)
                    .then((value: Response) => value.json())
                    .then((searchResult: IDealerLocation[]) => dispatch({ type: DealerLocationAction.RECEIVE_RESPONSE, searchResult: searchResult }))
                    .catch((reason: any) => dispatch({ type: DealerLocationAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            case DealerLocationSearchChoice.SEARCH_BY_DEALER_NAME:
                getDealerLocationByDealerName(state.dealerName)
                    .then((value: Response) => value.json())
                    .then((searchResult: IDealerLocation[]) => dispatch({ type: DealerLocationAction.RECEIVE_RESPONSE, searchResult: searchResult }))
                    .catch((reason: any) => dispatch({ type: DealerLocationAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            default:
                throw new Error('Invalid search choice.');
        }
    };

    const resetForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        _getAllDealerLocations();
    };

    const onEditDealerLocation = (dealerLocationId: number): void => {
        dispatch({ type: DealerLocationAction.EDIT_DEALER_LOCATION, dealerLocationId: dealerLocationId });
    };

    const onDeleteDealerLocation = (dealerLocationId: number): void => {
        deleteDealerLocationById(dealerLocationId)
            .then((value: Response) => dispatch({ type: DealerLocationAction.DELETE_DEALER_LOCATION }))
            .catch((reason: any) => dispatch({ type: DealerLocationAction.DELETE_DEALER_LOCATION }));
    };

    const onHideCreateModal = (): void => {
        dispatch({ type: DealerLocationAction.CLOSE_CREATE_MODAL });
    };

    const onHideEditModal = (): void => {
        dispatch({ type: DealerLocationAction.CLOSE_EDIT_MODAL });
    };

    const _createDealerLocation = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: DealerLocationAction.CREATE_DEALER_LOCATION });
    }

    return (
        <Stack gap={3}>
            <h1>Dealer Location Lookup</h1>
            <Form>
                <Stack gap={3}>
                    <Form.Group>
                        <Form.Check
                            id='dealerLocationSearchByIdRadio'
                            type='radio'
                            name='searchBy'
                            label={`Search By ID`}
                            onChange={_ => updateSearchBy(DealerLocationSearchChoice.SEARCH_BY_DEALER_ID)}
                            checked={state.searchType === DealerLocationSearchChoice.SEARCH_BY_DEALER_ID}
                            inline={true}
                            />
                        <Form.Check
                            id='dealerLocationSearchByNameRadio'
                            type='radio'
                            name='searchBy'
                            label={`Search By Name`}
                            onChange={_ => updateSearchBy(DealerLocationSearchChoice.SEARCH_BY_DEALER_NAME)}
                            checked={state.searchType === DealerLocationSearchChoice.SEARCH_BY_DEALER_NAME}
                            inline={true}
                            />
                    </Form.Group>
                    {state.searchType === DealerLocationSearchChoice.SEARCH_BY_DEALER_ID && (
                        <Form.Group>
                            <Form.Label>Dealer ID</Form.Label>
                            <Form.Control
                                id='dealerIdInput'
                                type='number'
                                placeholder='Enter Dealer ID'
                                value={state.dealerId}
                                onChange={updateDealerId}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    {state.searchType === DealerLocationSearchChoice.SEARCH_BY_DEALER_NAME && (
                        <Form.Group>
                            <Form.Label>Dealer Name</Form.Label>
                            <Form.Control
                                id='dealerNameInput'
                                type='string'
                                placeholder='Enter Dealer Name'
                                value={state.dealerName}
                                onChange={updateDealerName}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Stack gap={3} direction='horizontal'>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                disabled={state.disabled}
                                >
                                Search
                            </Button>
                            <Button
                                variant='secondary'
                                onClick={resetForm}
                                >
                                Reset
                            </Button>
                            <Button
                                variant='success'
                                onClick={_createDealerLocation}
                                >
                                Create New Dealer Location
                            </Button>
                        </Stack>
                    </Form.Group>
                </Stack>
            </Form>
            <DealerLocationsDataTable
                data={state.searchResult}
                onEdit={onEditDealerLocation}
                onDelete={onDeleteDealerLocation}
                />
            <CreateDealerLocationModal
                show={state.showCreateModal}
                onHide={onHideCreateModal}
                />
            <EditDealerLocationModal
                show={state.showEditModal}
                dealerLocationId={state.editDealerLocationId}
                onHide={onHideEditModal}
                />
        </Stack>
    );
};

export default DealerLocations;