import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Stack from 'react-bootstrap/Stack';
import { userPoolsReducer, initialState } from './UserPools.reducer';
import { UserPoolAction } from './UserPools.actions';
import { IUserPool } from './UserPools.types';
import { getUserPools } from './UserPools.service';

const UserPools = (): JSX.Element => {
    var [state, dispatch] = React.useReducer(userPoolsReducer, initialState);

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: UserPoolAction.SUBMIT_FORM });
        getUserPools(dispatch);
    };

    return(
        <Stack gap={3}>
            <h1>User Pools Lookup</h1>
            <Form>
                <Stack gap={3}>
                    <Form.Group>
                        <Button
                            variant='primary'
                            type='submit'
                            onClick={submitForm}
                            disabled={state.disabled}
                            >
                            Search
                        </Button>
                    </Form.Group>
                </Stack>
            </Form>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>User Pool Id</th>
                        <th>User Pool Name</th>
                        <th>Creation Date</th>
                        <th>Last Modified Date</th>
                        {/* <th>Lambda Config</th> */}
                        {/* <th>Status</th> */}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {state.searchResult && state.searchResult.map((value: IUserPool, index: number) => {
                        return (
                            <tr key={`row-${index}`}>
                                <td key={`userPool-id-${index}`}>{value.id}</td>
                                <td key={`userPool-name-${index}`}>{value.name}</td>
                                <td key={`userPool-creationDate-${index}`}>{value.creationDate}</td>
                                <td key={`userPool-lastModifiedDate-${index}`}>{value.lastModifiedDate}</td>
                                {/* <td key={`userPool-lambdaConfig-${index}`}>{value.lambdaConfig.toString()}</td> */}
                                {/* <td key={`userPool-status-${index}`}>{(value.status && value.status.toString()) || 'null'}</td> */}
                                <td key={`userPool-editCol-${index}`}><Button>Edit</Button></td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </Stack>
    );
};

export default UserPools;