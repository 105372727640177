import { IVendor } from "../Vendors.types";
import { EditVendorAction, EditVendorActionType } from "./EditVendorModal.actions";

export interface IEditVendorState {
    vendorId: string,
    vendorName: string,
    vendor: IVendor | undefined,
    disabled: boolean,
};

export const initialState: IEditVendorState = {
    vendorId: '',
    vendorName: '',
    vendor: undefined,
    disabled: false,
};

export const editVendorReducer = (state: IEditVendorState, action: EditVendorActionType): IEditVendorState => {
    switch (action.type) {
        case EditVendorAction.CHANGE_VENDOR_ID:
            return {
                ...state,
                vendorId: action.vendorId,
            }
        case EditVendorAction.CHANGE_VENDOR_NAME:
            return {
                ...state,
                vendorName: action.vendorName,
            };
        case EditVendorAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case EditVendorAction.RECEIVE_GET_RESPONSE:
            return {
                ...state,
                disabled: false,
                vendorId: action.searchResult?.vendorId || '',
                vendorName: action.searchResult?.vendorName || '',
            };
        case EditVendorAction.RECEIVE_PATCH_RESPONSE:
            return {
                ...state,
                disabled: false,
            };
        default:
            throw new Error("Invalid Action type.");
    }
};
