import { IApiAccess } from "../ApiAccess.types";

export enum EditApiAccessAction {
    CHANGE_CAN_READ = 'CHANGE_CAN_READ',
    CHANGE_CAN_WRITE = 'CHANGE_CAN_WRITE',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_GET_RESPONSE = 'RECEIVE_GET_RESPONSE',
    RECEIVE_PATCH_RESPONSE = 'RECEIVE_PATCH_RESPONSE',
};

export type EditApiAccessActionType = IChangeCanReadAction
    | IChangeCanWriteAction
    | ISubmitFormAction
    | IReceiveGetResponseAction
    | IReceivePatchResponseAction;

export interface IChangeCanReadAction {
    type: EditApiAccessAction.CHANGE_CAN_READ,
    canRead: boolean
};

export interface IChangeCanWriteAction {
    type: EditApiAccessAction.CHANGE_CAN_WRITE,
    canWrite: boolean,
}

export interface ISubmitFormAction {
    type: EditApiAccessAction.SUBMIT_FORM,
};

export interface IReceiveGetResponseAction {
    type: EditApiAccessAction.RECEIVE_GET_RESPONSE,
    searchResult: IApiAccess | undefined,
};

export interface IReceivePatchResponseAction {
    type: EditApiAccessAction.RECEIVE_PATCH_RESPONSE,
};
