import { IMicroservice } from "../../Microservices/Microservices.types";
import { IVendorDealerLocation } from "../../VendorDealerLocations";

export enum CreateApiAccessAction {
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_RESPONSE = 'RECEIVE_RESPONSE',
    RECEIVE_MICROSERVICES_RESPONSE = 'RECEIVE_MICROSERVICES_RESPONSE',
    RECEIVE_VENDOR_DEALER_LOCATIONS_RESPONSE = 'RECEIVE_VENDOR_DEALER_LOCATIONS_RESPONSE',
    SELECT_MICROSERVICE = 'SELECT_MICROSERVICE',
    SELECT_VENDOR_DEALER_LOCATION = 'SELECT_VENDOR_DEALER_LOCATION',
    UPDATE_CAN_READ = 'UPDATE_CAN_READ',
    UPDATE_CAN_WRITE = 'UPDATE_CAN_WRITE',
};

export type CreateApiAccessActionType = ISelectMicroserviceAction
    | ISubmitFormAction
    | IReceiveResponseAction
    | IReceiveMicroservicesResponseAction
    | IReceiveVendorDealerLocationsResponseAction
    | ISelectVendorDealerLocationAction
    | IUpdateCanReadAction
    | IUpdateCanWriteAction;

export interface ISelectMicroserviceAction {
    type: CreateApiAccessAction.SELECT_MICROSERVICE,
    selectedMicroserviceId: number,
};

export interface ISubmitFormAction {
    type: CreateApiAccessAction.SUBMIT_FORM,
};

export interface IReceiveResponseAction {
    type: CreateApiAccessAction.RECEIVE_RESPONSE,
};

export interface IReceiveMicroservicesResponseAction {
    type: CreateApiAccessAction.RECEIVE_MICROSERVICES_RESPONSE,
    microserviceOptions: IMicroservice[],
};

export interface IReceiveVendorDealerLocationsResponseAction {
    type: CreateApiAccessAction.RECEIVE_VENDOR_DEALER_LOCATIONS_RESPONSE,
    vendorDealerLocationOptions: IVendorDealerLocation[],
};

export interface ISelectVendorDealerLocationAction {
    type: CreateApiAccessAction.SELECT_VENDOR_DEALER_LOCATION,
    selectedVendorDealerLocationId: number,
};

export interface IUpdateCanReadAction {
    type: CreateApiAccessAction.UPDATE_CAN_READ,
    canRead: boolean,
};

export interface IUpdateCanWriteAction {
    type: CreateApiAccessAction.UPDATE_CAN_WRITE,
    canWrite: boolean,
};
