import { IDealer } from "../Dealers.types";
import { EditDealerAction, EditDealerActionType } from "./EditDealerModal.actions";

export interface IEditDealerState {
    dealerId: string,
    dealerName: string,
    dealer: IDealer | undefined,
    disabled: boolean,
};

export const initialState: IEditDealerState = {
    dealerId: '',
    dealerName: '',
    dealer: undefined,
    disabled: false,
};

export const editDealerReducer = (state: IEditDealerState, action: EditDealerActionType): IEditDealerState => {
    switch (action.type) {
        case EditDealerAction.CHANGE_DEALER_ID:
            return {
                ...state,
                dealerId: action.dealerId,
            };
        case EditDealerAction.CHANGE_DEALER_NAME:
            return {
                ...state,
                dealerName: action.dealerName,
            };
        case EditDealerAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case EditDealerAction.RECEIVE_GET_RESPONSE:
            return {
                ...state,
                disabled: false,
                dealerId: action.searchResult?.dealerId || '',
                dealerName: action.searchResult?.dealerName || '',
            };
        case EditDealerAction.RECEIVE_PATCH_RESPONSE:
            return {
                ...state,
                disabled: false,
            };
        default:
            throw new Error("Invalid Action type.");
    }
};
