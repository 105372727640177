import { IDealer } from "../../Dealers";

export enum CreateDealerLocationAction {
    CHANGE_DEALER_LOCATION_NAME = 'CHANGE_DEALER_LOCATION_NAME',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_RESPONSE = 'RECEIVE_RESPONSE',
    RECEIVE_DEALERS_RESPONSE = 'RECEIVE_DEALERS_RESPONSE',
    SELECT_DEALER = 'SELECT_DEALER',
    CHANGE_LOCATION_ID = 'CHANGE_LOCATION_ID',
};

export type CreateDealerLocationActionType = IChangeDealerLocationNameAction
    | ISubmitFormAction
    | IReceiveResponseAction
    | IReceiveDealersResponseAction
    | ISelectDealerAction
    | IChangeLocationIdAction;

export interface IChangeDealerLocationNameAction {
    type: CreateDealerLocationAction.CHANGE_DEALER_LOCATION_NAME,
    dealerLocationName: string,
};

export interface ISubmitFormAction {
    type: CreateDealerLocationAction.SUBMIT_FORM,
};

export interface IReceiveResponseAction {
    type: CreateDealerLocationAction.RECEIVE_RESPONSE,
};

export interface IReceiveDealersResponseAction {
    type: CreateDealerLocationAction.RECEIVE_DEALERS_RESPONSE,
    dealerOptions: IDealer[],
};

export interface ISelectDealerAction {
    type: CreateDealerLocationAction.SELECT_DEALER,
    dealerPrimaryKeyId: number,
};

export interface IChangeLocationIdAction {
    type: CreateDealerLocationAction.CHANGE_LOCATION_ID,
    locationId: number,
}