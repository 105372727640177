import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { IApiAccess, IApiAccessDataTableProps } from "../ApiAccess.types";

const ApiAccessDataTable = (props: IApiAccessDataTableProps): JSX.Element => {
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Vendor Name</th>
                    <th>Can Read</th>
                    <th>Can Write</th>
                    <th>Dealer Name</th>
                    <th>Dealer Id</th>
                    <th>Location Name</th>
                    <th>Location Id</th>
                    <th>API Name</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.data && props.data.map((value: IApiAccess, index: number) => {
                    return (
                        <tr key={`row-${index}`}>
                            <td key={`apiAccess-id-${index}`}>{value.id}</td>
                            <td key={`apiAccess-vendorName-${index}`}>{value.vendorName}</td>
                            <td key={`apiAccess-canRead-${index}`}>{value.canRead.toString()}</td>
                            <td key={`apiAccess-canWrite-id-${index}`}>{value.canWrite.toString()}</td>
                            <td key={`apiAccess-dealerName-${index}`}>{value.dealerName}</td>
                            <td key={`apiAccess-dealerId-${index}`}>{value.dealerId}</td>
                            <td key={`apiAccess-locationName-${index}`}>{value.locationName}</td>
                            <td key={`apiAccess-locationId-${index}`}>{value.locationId}</td>
                            <td key={`apiAccess-microserviceName-${index}`}>{value.microserviceName}</td>
                            <td key={`apiAccess-editCol-${index}`}>
                                <Button
                                    variant='secondary'
                                    onClick={_ => props.onEdit(value.id)}
                                    >
                                    Edit
                                </Button>
                            </td>
                            <td key={`apiAccess-deleteCol-${index}`}>
                                <Button
                                    variant='danger'
                                    onClick={_ => props.onDelete(value.id)}
                                    >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default ApiAccessDataTable;