import { CreateDealerAction, CreateDealerActionType } from "./CreateDealerModal.actions";

export interface ICreateDealerState {
    dealerName: string,
    dealerId: string,
    disabled: boolean,
};

export const initialState: ICreateDealerState = {
    dealerName: '',
    dealerId: '',
    disabled: false,
};

export const createDealerReducer = (state: ICreateDealerState, action: CreateDealerActionType): ICreateDealerState => {
    switch (action.type) {
        case CreateDealerAction.CHANGE_DEALER_NAME:
            return {
                ...state,
                dealerName: action.dealerName,
            };
        case CreateDealerAction.CHANGE_DEALER_ID:
            return {
                ...state,
                dealerId: action.dealerId,
            };
        case CreateDealerAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case CreateDealerAction.RECEIVE_RESPONSE:
            return {
                ...state,
                disabled: false,
            };
        default:
            throw new Error("Invalid Action type.");
    }
};
