const { REACT_APP_BASE_URL } = process.env;

export const getAllDealers = (): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}dealers/getalldealers`);
};

export const getDealerById = (dealerId: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}dealers/getdealerbyid?dealerId=${dealerId}`);
};

export const getDealerByName = (dealerName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}dealers/searchdealersbyname?nameSubstring=${dealerName}`);
};

export const deleteDealerById = (dealerPrimaryKeyId: number): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}dealers/deletedealerbyid?dealerPrimaryKeyId=${dealerPrimaryKeyId}`, {
        method: 'DELETE',
    });
};