import { DealerLocationAction, DealerLocationActionType, DealerLocationSearchChoice } from './DealerLocations.actions';
import { IDealerLocation } from './DealerLocations.types';

export interface IDealerLocationState {
    dealerId: string,
    dealerName: string,
    disabled: boolean,
    searchResult: IDealerLocation[],
    searchType: DealerLocationSearchChoice,
    showCreateModal: boolean,
    showEditModal: boolean,
    editDealerLocationId: number,
    createFlag: boolean,
    deleteFlag: boolean,
};

export const initialState: IDealerLocationState = {
    dealerId: '',
    dealerName: '',
    disabled: false,
    searchResult: [],
    searchType: DealerLocationSearchChoice.SEARCH_BY_DEALER_ID,
    showCreateModal: false,
    showEditModal: false,
    editDealerLocationId: 0,
    createFlag: false,
    deleteFlag: false,
};

export const dealerLocationReducer = (state: IDealerLocationState, action: DealerLocationActionType): IDealerLocationState => {
    switch (action.type) {
        case DealerLocationAction.CHANGE_DEALER_ID:
            return {
                ...state,
                dealerId: action.dealerId,
            };
        case DealerLocationAction.CHANGE_DEALER_NAME:
            return {
                ...state,
                dealerName: action.dealerName,
            };
        case DealerLocationAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case DealerLocationAction.RECEIVE_RESPONSE:
            return {
                ...state,
                disabled: false,
                searchResult: action.searchResult,
            };
        case DealerLocationAction.CHANGE_DEALER_LOCATION_SEARCH_CHOICE:
            return {
                ...state,
                searchType: action.searchType,
            };
        case DealerLocationAction.CREATE_DEALER_LOCATION:
            return {
                ...state,
                showCreateModal: true,
            };
        case DealerLocationAction.EDIT_DEALER_LOCATION:
            return {
                ...state,
                showEditModal: true,
                editDealerLocationId: action.dealerLocationId,
            };
        case DealerLocationAction.DELETE_DEALER_LOCATION:
            return {
                ...state,
                deleteFlag: !state.deleteFlag,
            };
        case DealerLocationAction.CLOSE_CREATE_MODAL:
            return {
                ...state,
                showCreateModal: false,
                createFlag: !state.createFlag,
            };
        case DealerLocationAction.CLOSE_EDIT_MODAL:
            return {
                ...state,
                showEditModal: false,
                editDealerLocationId: 0,
            };
        default:
            throw new Error('Invalid action type.');
    }
};