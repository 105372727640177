import { MicroserviceAction, MicroserviceActionType } from './Microservices.actions';
import { IMicroservice } from './Microservices.types';

export interface IMicroserviceState {
    microserviceName: string,
    disabled: boolean,
    searchResult: IMicroservice[],
    showCreateModal: boolean,
    showEditModal: boolean,
    editMicroserviceId: number,
    createFlag: boolean,
    deleteFlag: boolean,
};

export const initialState: IMicroserviceState = {
    microserviceName: '',
    disabled: false,
    searchResult: [],
    showCreateModal: false,
    showEditModal: false,
    editMicroserviceId: 0,
    createFlag: false,
    deleteFlag: false,
};

export const microserviceReducer = (state: IMicroserviceState, action: MicroserviceActionType): IMicroserviceState => {
    switch (action.type) {
        case MicroserviceAction.CHANGE_MICROSERVICE_NAME:
            return {
                ...state,
                microserviceName: action.microserviceName,
            };
        case MicroserviceAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case MicroserviceAction.RECEIVE_GET_RESPONSE:
            return {
                ...state,
                disabled: false,
                searchResult: action.searchResult,
            };
        case MicroserviceAction.CREATE_MICROSERVICE:
            return {
                ...state,
                showCreateModal: true,
            };
        case MicroserviceAction.EDIT_MICROSERVICE:
            return {
                ...state,
                showEditModal: true,
                editMicroserviceId: action.microserviceId,
            };
        case MicroserviceAction.DELETE_MICROSERVICE:
            return {
                ...state,
                deleteFlag: !state.deleteFlag,
            };
        case MicroserviceAction.CLOSE_CREATE_MODAL:
            return {
                ...state,
                showCreateModal: false,
                createFlag: !state.createFlag,
            };
        case MicroserviceAction.CLOSE_EDIT_MODAL:
            return {
                ...state,
                showEditModal: false,
                editMicroserviceId: 0,
            };
        default:
            throw new Error('Invalid action type.');
    }
};