import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { ICreateVendorDealerLocationProps } from '../VendorDealerLocations.types';
import { createVendorDealerLocationReducer, initialState } from './CreateVendorDealerLocationModal.reducer';
import { CreateVendorDealerLocationAction } from './CreateVendorDealerLocationModal.actions';
import { createVendorDealerLocation, getDealerLocationsByDealerPrimaryKeyId } from './CreateVendorDealerLocationModal.service';
import { IDealer, getAllDealers } from '../../Dealers';
import { IVendor, getAllVendors } from '../../Vendors';
import { IDealerLocation } from '../../DealerLocations';

const CreateVendorDealerLocationModal = (props: ICreateVendorDealerLocationProps): JSX.Element => {
    const [state, dispatch] = React.useReducer(createVendorDealerLocationReducer, initialState);

    React.useEffect(() => {
        getAllVendors()
            .then((value: Response) => value.json())
            .then((searchResult: IVendor[]) => dispatch({ type: CreateVendorDealerLocationAction.RECEIVE_VENDORS_RESPONSE, vendorOptions: searchResult}))
            .catch((reason: any) => dispatch({ type: CreateVendorDealerLocationAction.RECEIVE_VENDORS_RESPONSE, vendorOptions: [] }));
    }, []);

    React.useEffect(() => {
        if (!state.vendorOptions || state.vendorOptions.length === 0) {
            dispatch({ type: CreateVendorDealerLocationAction.CHANGE_VENDOR, vendorPrimaryKeyId: 0 });
            return;
        }

        dispatch({ type: CreateVendorDealerLocationAction.CHANGE_VENDOR, vendorPrimaryKeyId: state.vendorOptions[0].id });
    }, [state.vendorOptions]);

    React.useEffect(() => {
        if (!state.dealerOptions || state.dealerOptions.length === 0) {
            dispatch({ type: CreateVendorDealerLocationAction.SELECT_DEALER, dealerPrimaryKeyId: 0 });
            return;
        }

        dispatch({ type: CreateVendorDealerLocationAction.SELECT_DEALER, dealerPrimaryKeyId: state.dealerOptions[0].id });
    }, [state.dealerOptions]);

    React.useEffect(() => {
        if (!state.dealerLocationOptions || state.dealerLocationOptions.length === 0) {
            dispatch({ type: CreateVendorDealerLocationAction.SELECT_DEALER_LOCATION, dealerLocationId: 0 });
            return;
        }

        dispatch({ type: CreateVendorDealerLocationAction.SELECT_DEALER_LOCATION, dealerLocationId: state.dealerLocationOptions[0].id });
    }, [state.dealerLocationOptions]);

    React.useEffect(() => {
        getAllDealers()
            .then((value: Response) => value.json())
            .then((searchResult: IDealer[]) => dispatch({ type: CreateVendorDealerLocationAction.RECEIVE_DEALERS_RESPONSE, dealerOptions: searchResult }))
            .catch((reason: any) => dispatch({ type: CreateVendorDealerLocationAction.RECEIVE_DEALERS_RESPONSE, dealerOptions: [] }));
    }, [state.selectedVendorPrimaryKeyId]);

    React.useEffect(() => {
        getDealerLocationsByDealerPrimaryKeyId(state.selectedDealerPrimaryKeyId)
            .then((value: Response) => value.json())
            .then((searchResult: IDealerLocation[]) => dispatch({ type: CreateVendorDealerLocationAction.RECEIVE_DEALER_LOCATIONS_RESPONSE, dealerLocationOptions: searchResult }))
            .catch((reason: any) => dispatch({ type: CreateVendorDealerLocationAction.RECEIVE_DEALER_LOCATIONS_RESPONSE, dealerLocationOptions: [] }));
    }, [state.selectedDealerPrimaryKeyId]);

    var { onHide } = props;

    React.useEffect(() => {
        if (state.disabled) {
            createVendorDealerLocation(state.selectedVendorPrimaryKeyId, state.selectedDealerLocationId)
                .then((value: Response) => dispatch({ type: CreateVendorDealerLocationAction.RECEIVE_RESPONSE }))
                .then((value: void) => onHide())
                .catch((reason: any) => dispatch({ type: CreateVendorDealerLocationAction.RECEIVE_RESPONSE }));
        }
    }, [state.disabled, state.selectedVendorPrimaryKeyId, state.selectedDealerLocationId, onHide])

    const updateSelectedVendorId = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        var newSelectedVendorPrimaryKeyId = parseInt(e.target.value);

        dispatch({ type: CreateVendorDealerLocationAction.CHANGE_VENDOR, vendorPrimaryKeyId: newSelectedVendorPrimaryKeyId });
    };

    const updateSelectedDealerId = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        var newSelectedDealerPrimaryKeyId = parseInt(e.target.value);
        
        dispatch({ type: CreateVendorDealerLocationAction.SELECT_DEALER, dealerPrimaryKeyId: newSelectedDealerPrimaryKeyId });
    };

    const updateDealerLocationId = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        var newDealerLocationId = parseInt(e.target.value);

        dispatch({ type: CreateVendorDealerLocationAction.SELECT_DEALER_LOCATION, dealerLocationId: newDealerLocationId });
    };

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: CreateVendorDealerLocationAction.SUBMIT_FORM });
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop='static'
            keyboard={false}
            >
            <Modal.Header closeButton={true}>
                <Modal.Title>Create Association</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Stack gap={3}>
                            <Form.Group>
                                <Form.Label>Vendor</Form.Label>
                                <Form.Select
                                    id='createVendorDealerLocationVendorSelect'
                                    onChange={updateSelectedVendorId}
                                    disabled={state.disabled}
                                    value={state.selectedVendorPrimaryKeyId}
                                    >
                                    {state.vendorOptions && state.vendorOptions.map((value: IVendor, index: number) => {
                                        return(
                                            <option
                                                key={`vendorOption-${index}`}
                                                value={`${value.id}`}
                                                >
                                                {value.vendorName}
                                            </option>
                                        );
                                    })}
                                    </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Dealer</Form.Label>
                                <Form.Select
                                    id='createVendorDealerLocationDealerSelect'
                                    onChange={updateSelectedDealerId}
                                    disabled={state.disabled}
                                    value={state.selectedDealerPrimaryKeyId}
                                    >
                                    {state.dealerOptions && state.dealerOptions.map((value: IDealer, index: number) => {
                                        return(
                                            <option
                                                key={`dealerOption-${index}`}
                                                value={`${value.id}`}
                                                >
                                                {value.dealerName}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Dealer Location</Form.Label>
                                <Form.Select
                                    id='createVendorDealerLocationDealerLocationSelect'
                                    onChange={updateDealerLocationId}
                                    disabled={state.disabled}
                                    value={state.selectedDealerLocationId}
                                    >
                                    {state.dealerLocationOptions && state.dealerLocationOptions.map((value: IDealerLocation, index: number) => {
                                        return(
                                            <option
                                                key={`dealerLocationOption-${index}`}
                                                value={`${value.id}`}
                                                >
                                                {value.locationName}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                >
                                Create New Location
                            </Button>
                        </Stack>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateVendorDealerLocationModal;