const { REACT_APP_BASE_URL } = process.env;

export const createVendorDealerLocation = (vendorId: number, dealerLocationId: number): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}vendordealerlocations/createvendordealerlocation`, {
        method: 'POST',
        body: JSON.stringify({
            vendorPrimaryKeyId: vendorId,
            dealerLocationId: dealerLocationId,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const getDealerLocationsByDealerPrimaryKeyId = (dealerLocationPrimaryKeyId: number): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}dealerlocations/getdealerlocationsbydealerprimarykeyid?dealerPrimaryKeyId=${dealerLocationPrimaryKeyId}`);
}