import { IVendor } from "../Vendors.types";

export enum EditVendorAction {
    CHANGE_VENDOR_ID = 'CHANGE_VENDOR_ID',
    CHANGE_VENDOR_NAME = 'CHANGE_VENDOR_NAME',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_GET_RESPONSE = 'RECEIVE_GET_RESPONSE',
    RECEIVE_PATCH_RESPONSE = 'RECEIVE_PATCH_RESPONSE',
};

export type EditVendorActionType = IChangeVendorNameAction
    | IChangeVendorIdAction
    | ISubmitFormAction
    | IReceiveGetResponseAction
    | IReceivePatchResponseAction;

export interface IChangeVendorNameAction {
    type: EditVendorAction.CHANGE_VENDOR_NAME,
    vendorName: string,
};

export interface IChangeVendorIdAction {
    type: EditVendorAction.CHANGE_VENDOR_ID,
    vendorId: string,
};

export interface ISubmitFormAction {
    type: EditVendorAction.SUBMIT_FORM,
};

export interface IReceiveGetResponseAction {
    type: EditVendorAction.RECEIVE_GET_RESPONSE,
    searchResult: IVendor | undefined,
};

export interface IReceivePatchResponseAction {
    type: EditVendorAction.RECEIVE_PATCH_RESPONSE,
}
