const { REACT_APP_BASE_URL } = process.env;

export const createDealer = (dealerId: string, dealerName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}dealers/createdealer`, {
        method: 'POST',
        body: JSON.stringify({
            dealerId: dealerId,
            dealerName: dealerName,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
