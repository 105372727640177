import React from 'react';
import { ApiAccessAction, ApiAccessSearchChoice } from './ApiAccess.actions';
import { apiAccessReducer, initialState } from './ApiAccess.reducer';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { getApiAccessByMicroserviceId, getApiAccessByMicroserviceName, getApiAccessByVendorId, getApiAccessByVendorName, getApiAccessByDealerId, getApiAccessByDealerName, deleteApiAccessById, getAllApiAccessRecords } from './ApiAccess.service';
import Stack from 'react-bootstrap/Stack';
import ApiAccessDataTable from './DataTable';
import { IApiAccess } from './ApiAccess.types';
import CreateApiAccessModal from './Create';
import EditApiAccessModal from './Edit';

const ApiAccess = (): JSX.Element => {
    const [state, dispatch] = React.useReducer(apiAccessReducer, initialState);

    React.useEffect(() => {
        _getAllApiAccessRecords();
    }, []);

    React.useEffect(() => {
        _getAllApiAccessRecords();
    }, [state.createFlag, state.deleteFlag, state.showEditModal])

    const _getAllApiAccessRecords = (): void => {
        getAllApiAccessRecords()
            .then((value: Response) => value.json())
            .then((searchResult: IApiAccess[]) => dispatch({ type: ApiAccessAction.RECEIVE_RESPONSE, searchResult: searchResult }))
            .catch((reason: any) => dispatch({ type: ApiAccessAction.RECEIVE_RESPONSE, searchResult: [] }));
    }

    const updateMicroserviceId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        var newMicroserviceId = parseInt(e.target.value);
        dispatch({ type: ApiAccessAction.CHANGE_MICROSERVICE_ID, microserviceId: newMicroserviceId });
    };

    const updateMicroserviceName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: ApiAccessAction.CHANGE_MICROSERVICE_NAME, microserviceName: e.target.value });
    };

    const updateVendorId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: ApiAccessAction.CHANGE_VENDOR_ID, vendorId: e.target.value });
    };

    const updateVendorName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: ApiAccessAction.CHANGE_VENDOR_NAME, vendorName: e.target.value });
    };

    const updateDealerId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: ApiAccessAction.CHANGE_DEALER_ID, dealerId: e.target.value });
    };

    const updateDealerName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: ApiAccessAction.CHANGE_DEALER_NAME, dealerName: e.target.value });
    };

    const updateSearchBy = (choice: ApiAccessSearchChoice): void => {
        dispatch({ type: ApiAccessAction.CHANGE_API_ACCESS_SEARCH_CHOICE, searchType: choice})
    }

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: ApiAccessAction.SUBMIT_FORM });
        switch (state.searchType) {
            case ApiAccessSearchChoice.SEARCH_BY_MICROSERVICE_ID:
                getApiAccessByMicroserviceId(state.microserviceId)
                    .then((value: Response) => value.json())
                    .then((searchResult: IApiAccess[]) => dispatch({ type: ApiAccessAction.RECEIVE_RESPONSE, searchResult: searchResult }))
                    .catch((reason: any) => dispatch({ type: ApiAccessAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            case ApiAccessSearchChoice.SEARCH_BY_MICROSERVICE_NAME:
                getApiAccessByMicroserviceName(state.microserviceName)
                    .then((value: Response) => value.json())
                    .then((searchResult: IApiAccess[]) => dispatch({ type: ApiAccessAction.RECEIVE_RESPONSE, searchResult: searchResult }))
                    .catch((reason: any) => dispatch({ type: ApiAccessAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            case ApiAccessSearchChoice.SEARCH_BY_VENDOR_ID:
                getApiAccessByVendorId(state.vendorId)
                    .then((value: Response) => value.json())
                    .then((searchResult: IApiAccess[]) => dispatch({ type: ApiAccessAction.RECEIVE_RESPONSE, searchResult: searchResult }))
                    .catch((reason: any) => dispatch({ type: ApiAccessAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            case ApiAccessSearchChoice.SEARCH_BY_VENDOR_NAME:
                getApiAccessByVendorName(state.vendorName)
                    .then((value: Response) => value.json())
                    .then((searchResult: IApiAccess[]) => dispatch({ type: ApiAccessAction.RECEIVE_RESPONSE, searchResult: searchResult }))
                    .catch((reason: any) => dispatch({ type: ApiAccessAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            case ApiAccessSearchChoice.SEARCH_BY_DEALER_ID:
                getApiAccessByDealerId(state.dealerId)
                    .then((value: Response) => value.json())
                    .then((searchResult: IApiAccess[]) => dispatch({ type: ApiAccessAction.RECEIVE_RESPONSE, searchResult: searchResult }))
                    .catch((reason: any) => dispatch({ type: ApiAccessAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            case ApiAccessSearchChoice.SEARCH_BY_DEALER_NAME:
                getApiAccessByDealerName(state.dealerName)
                    .then((value: Response) => value.json())
                    .then((searchResult: IApiAccess[]) => dispatch({ type: ApiAccessAction.RECEIVE_RESPONSE, searchResult: searchResult }))
                    .catch((reason: any) => dispatch({ type: ApiAccessAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            default:
                throw new Error('Invalid search choice.');
        }
    };

    const onEditApiAccess = (apiAccessId: number): void => {
        dispatch({ type: ApiAccessAction.EDIT_API_ACCESS, apiAccessId: apiAccessId });
    };

    const onDeleteApiAccess = (apiAccessId: number): void => {
        deleteApiAccessById(apiAccessId)
            .then((value: Response) => dispatch({ type: ApiAccessAction.DELETE_API_ACCESS }))
            .catch((reason: any) => dispatch({ type: ApiAccessAction.DELETE_API_ACCESS }));
    };

    const resetForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        _getAllApiAccessRecords();
    };

    const _createApiAccessRecord = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: ApiAccessAction.CREATE_API_ACCESS });
    }

    const onHideCreateModal = (): void => {
        dispatch({ type: ApiAccessAction.CLOSE_CREATE_MODAL });
    };

    const onHideEditModal = (): void => {
        dispatch({ type: ApiAccessAction.CLOSE_EDIT_MODAL });
    };

    return (
        <Stack gap={3}>
            <h1>API Access Lookup</h1>
            <Form>
                <Stack gap={3}>
                    <Form.Group>
                        <Form.Check
                            id='apiAccessSearchByMicroserviceIdRadio'
                            type='radio'
                            name='searchBy'
                            label='Search By Microservice ID'
                            onChange={_ => updateSearchBy(ApiAccessSearchChoice.SEARCH_BY_MICROSERVICE_ID)}
                            checked={state.searchType === ApiAccessSearchChoice.SEARCH_BY_MICROSERVICE_ID}
                            inline={true}
                            />
                        <Form.Check
                            id='apiAccessSearchByMicroserviceNameRadio'
                            type='radio'
                            name='searchBy'
                            label='Search By Microservice Name'
                            onChange={_ => updateSearchBy(ApiAccessSearchChoice.SEARCH_BY_MICROSERVICE_NAME)}
                            checked={state.searchType === ApiAccessSearchChoice.SEARCH_BY_MICROSERVICE_NAME}
                            inline={true}
                            />
                        <Form.Check
                            id='apiAccessSearchByVendorIdRadio'
                            type='radio'
                            name='searchBy'
                            label={`Search By Vendor ID`}
                            onChange={_ => updateSearchBy(ApiAccessSearchChoice.SEARCH_BY_VENDOR_ID)}
                            checked={state.searchType === ApiAccessSearchChoice.SEARCH_BY_VENDOR_ID}
                            inline={true}
                            />
                        <Form.Check
                            id='apiAccessSearchByVendorNameRadio'
                            type='radio'
                            name='searchBy'
                            label={`Search By Vendor Name`}
                            onChange={_ => updateSearchBy(ApiAccessSearchChoice.SEARCH_BY_VENDOR_NAME)}
                            checked={state.searchType === ApiAccessSearchChoice.SEARCH_BY_VENDOR_NAME}
                            inline={true}
                            />
                        <Form.Check
                            id='apiAccessSearchByDealerIdRadio'
                            type='radio'
                            name='searchBy'
                            label={`Search By Dealer ID`}
                            onChange={_ => updateSearchBy(ApiAccessSearchChoice.SEARCH_BY_DEALER_ID)}
                            checked={state.searchType === ApiAccessSearchChoice.SEARCH_BY_DEALER_ID}
                            inline={true}
                            />
                        <Form.Check
                            id='apiAccessSearchByDealerNameRadio'
                            type='radio'
                            name='searchBy'
                            label={`Search By Dealer Name`}
                            onChange={_ => updateSearchBy(ApiAccessSearchChoice.SEARCH_BY_DEALER_NAME)}
                            checked={state.searchType === ApiAccessSearchChoice.SEARCH_BY_DEALER_NAME}
                            inline={true}
                            />
                    </Form.Group>
                    {state.searchType === ApiAccessSearchChoice.SEARCH_BY_MICROSERVICE_ID && (
                        <Form.Group>
                            <Form.Label>Microservice ID</Form.Label>
                            <Form.Control
                                id='microserviceIdInput'
                                type='string'
                                placeholder='Enter Microservice ID'
                                value={state.microserviceId}
                                onChange={updateMicroserviceId}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    {state.searchType === ApiAccessSearchChoice.SEARCH_BY_MICROSERVICE_NAME && (
                        <Form.Group>
                            <Form.Label>Microservice Name</Form.Label>
                            <Form.Control
                                id='microserviceNameInput'
                                type='string'
                                placeholder='Enter Microservice Name'
                                value={state.microserviceName}
                                onChange={updateMicroserviceName}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    {state.searchType === ApiAccessSearchChoice.SEARCH_BY_VENDOR_ID && (
                        <Form.Group>
                            <Form.Label>Vendor ID</Form.Label>
                            <Form.Control
                                id='vendorIdInput'
                                type='string'
                                placeholder='Enter Vendor ID'
                                value={state.vendorId}
                                onChange={updateVendorId}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    {state.searchType === ApiAccessSearchChoice.SEARCH_BY_VENDOR_NAME && (
                        <Form.Group>
                            <Form.Label>Vendor Name</Form.Label>
                            <Form.Control
                                id='vendorNameInput'
                                type='string'
                                placeholder='Enter Vendor Name'
                                value={state.vendorName}
                                onChange={updateVendorName}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    {state.searchType === ApiAccessSearchChoice.SEARCH_BY_DEALER_ID && (
                        <Form.Group>
                            <Form.Label>Dealer ID</Form.Label>
                            <Form.Control
                                id='dealerIdInput'
                                type='string'
                                placeholder='Enter Dealer ID'
                                value={state.dealerId}
                                onChange={updateDealerId}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    {state.searchType === ApiAccessSearchChoice.SEARCH_BY_DEALER_NAME && (
                        <Form.Group>
                            <Form.Label>Dealer Name</Form.Label>
                            <Form.Control
                                id='dealerNameInput'
                                type='string'
                                placeholder='Enter Dealer Name'
                                value={state.dealerName}
                                onChange={updateDealerName}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Stack gap={3} direction='horizontal'>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                disabled={state.disabled}
                                >
                                Search
                            </Button>
                            <Button
                                variant='secondary'
                                onClick={resetForm}
                                >
                                Reset
                            </Button>
                            <Button
                                variant='success'
                                onClick={_createApiAccessRecord}
                                >
                                Create New Api Access
                            </Button>
                        </Stack>
                        
                    </Form.Group>
                </Stack>
            </Form>
            <ApiAccessDataTable
                data={state.searchResult}
                onEdit={onEditApiAccess}
                onDelete={onDeleteApiAccess}
                />
            <CreateApiAccessModal
                show={state.showCreateModal}
                onHide={onHideCreateModal}
                />
            <EditApiAccessModal
                show={state.showEditModal}
                apiAccessId={state.editApiAccessId}
                onHide={onHideEditModal}
                />
        </Stack>
    );
};

export default ApiAccess;