import { IDealerLocation } from "../DealerLocations.types";
import { EditDealerLocationAction, EditDealerLocationActionType } from "./EditDealerLocationModal.actions";

export interface IEditDealerLocationState {
    dealerLocationName: string,
    dealerLocation: IDealerLocation | undefined,
    disabled: boolean,
};

export const initialState: IEditDealerLocationState = {
    dealerLocationName: '',
    dealerLocation: undefined,
    disabled: false,
};

export const editDealerLocationReducer = (state: IEditDealerLocationState, action: EditDealerLocationActionType): IEditDealerLocationState => {
    switch (action.type) {
        case EditDealerLocationAction.CHANGE_DEALER_LOCATION_NAME:
            return {
                ...state,
                dealerLocationName: action.dealerLocationName,
            };
        case EditDealerLocationAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case EditDealerLocationAction.RECEIVE_GET_RESPONSE:
            return {
                ...state,
                disabled: false,
                dealerLocation: action.searchResult,
                dealerLocationName: action.searchResult?.locationName || '',
            };
        case EditDealerLocationAction.RECEIVE_PATCH_RESPONSE:
            return {
                ...state,
                disabled: false,
            };
        default:
            throw new Error("Invalid Action type.");
    }
};
