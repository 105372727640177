import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { IEditDealerProps, IDealer } from '../Dealers.types';
import { editDealerReducer, initialState } from './EditDealerModal.reducer';
import { getDealerByKey, updateDealerName } from './EditDealerModal.service';
import { EditDealerAction } from './EditDealerModal.actions';

const EditDealerModal = (props: IEditDealerProps): JSX.Element => {
    const [state, dispatch] = React.useReducer(editDealerReducer, initialState);

    React.useEffect(() => {
        if (props.dealerPrimaryKeyId === 0) {
            return;
        }

        getDealerByKey(props.dealerPrimaryKeyId)
            .then((value: Response) => value.json())
            .then((searchResult: IDealer) => dispatch({ type: EditDealerAction.RECEIVE_GET_RESPONSE, searchResult: searchResult }))
            .catch((reason: any) => dispatch({ type: EditDealerAction.RECEIVE_GET_RESPONSE, searchResult: undefined }));
    }, [props.dealerPrimaryKeyId]);

    const updateDealerId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: EditDealerAction.CHANGE_DEALER_ID, dealerId: e.target.value });
    };

    const _updateDealerName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: EditDealerAction.CHANGE_DEALER_NAME, dealerName: e.target.value });
    };

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: EditDealerAction.SUBMIT_FORM });

        updateDealerName(props.dealerPrimaryKeyId, state.dealerId, state.dealerName)
            .then((value: Response) => dispatch({ type: EditDealerAction.RECEIVE_PATCH_RESPONSE }))
            .then((value: void) => props.onHide())
            .catch((reason: any) => dispatch({ type: EditDealerAction.RECEIVE_PATCH_RESPONSE }));
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop='static'
            keyboard={false}
            >
            <Modal.Header closeButton={true}>
                <Modal.Title>Edit Dealer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Stack gap={3}>
                        <Form.Group>
                            <Form.Label>Dealer Id</Form.Label>
                            <Form.Control
                                id='editDealerIdReadOnlyInput'
                                type='string'
                                value={state.dealerId}
                                onChange={updateDealerId}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Dealer Name</Form.Label>
                            <Form.Control
                                id='editDealerNameInput'
                                type='string'
                                placeholder='Edit Service Name'
                                value={state.dealerName}
                                onChange={_updateDealerName}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                        <Form.Group>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                >
                                Edit
                            </Button>
                        </Form.Group>
                        </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditDealerModal;