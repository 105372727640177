import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { IEditVendorProps, IVendor } from '../Vendors.types';
import { editVendorReducer, initialState } from './EditVendorModal.reducer';
import { getVendorByKey, updateVendorName } from './EditVendorModal.service';
import { EditVendorAction } from './EditVendorModal.actions';

const EditVendorModal = (props: IEditVendorProps): JSX.Element => {
    const [state, dispatch] = React.useReducer(editVendorReducer, initialState);

    React.useEffect(() => {
        if (props.vendorPrimaryKeyId === 0) {
            return;
        }

        getVendorByKey(props.vendorPrimaryKeyId)
            .then((value: Response) => value.json())
            .then((searchResult: IVendor) => dispatch({ type: EditVendorAction.RECEIVE_GET_RESPONSE, searchResult: searchResult }))
            .catch((reason: any) => dispatch({ type: EditVendorAction.RECEIVE_GET_RESPONSE, searchResult: undefined }));
    }, [props.vendorPrimaryKeyId]);

    const updateVendorId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: EditVendorAction.CHANGE_VENDOR_ID, vendorId: e.target.value });
    };

    const _updateVendorName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: EditVendorAction.CHANGE_VENDOR_NAME, vendorName: e.target.value });
    };

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: EditVendorAction.SUBMIT_FORM });

        updateVendorName(props.vendorPrimaryKeyId, state.vendorId, state.vendorName)
            .then((value: Response) => dispatch({ type: EditVendorAction.RECEIVE_PATCH_RESPONSE }))
            .then((value: void) => props.onHide())
            .catch((reason: any) => dispatch({ type: EditVendorAction.RECEIVE_PATCH_RESPONSE }));
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop='static'
            keyboard={false}
            >
            <Modal.Header closeButton={true}>
                <Modal.Title>Edit Vendor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Stack gap={3}>
                        <Form.Group>
                            <Form.Label>Id</Form.Label>
                            <Form.Control
                                id='editVendorIdReadOnlyInput'
                                type='string'
                                value={state.vendorId}
                                onChange={updateVendorId}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Vendor Name</Form.Label>
                            <Form.Control
                                id='editVendorNameInput'
                                type='string'
                                placeholder='Edit Service Name'
                                value={state.vendorName}
                                onChange={_updateVendorName}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                        <Form.Group>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                >
                                Edit
                            </Button>
                        </Form.Group>
                        </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditVendorModal;