const { REACT_APP_BASE_URL } = process.env;

export const createMicroservice = (microserviceName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}microservices/createmicroservice`, {
        method: 'POST',
        body: JSON.stringify({
            microserviceName: microserviceName,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
