import { IMicroservice } from "../Microservices.types";
import { EditMicroserviceAction, EditMicroserviceActionType } from "./EditMicroserviceModal.actions";

export interface IEditMicroserviceState {
    microserviceName: string,
    microservice: IMicroservice | undefined,
    disabled: boolean,
};

export const initialState: IEditMicroserviceState = {
    microserviceName: '',
    microservice: undefined,
    disabled: false,
};

export const editMicroserviceReducer = (state: IEditMicroserviceState, action: EditMicroserviceActionType): IEditMicroserviceState => {
    switch (action.type) {
        case EditMicroserviceAction.CHANGE_MICROSERVICE_NAME:
            return {
                ...state,
                microserviceName: action.microserviceName,
            };
        case EditMicroserviceAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case EditMicroserviceAction.RECEIVE_GET_RESPONSE:
            return {
                ...state,
                disabled: false,
                microserviceName: action.searchResult?.microserviceName || '',
            };
        case EditMicroserviceAction.RECEIVE_PATCH_RESPONSE:
            return {
                ...state,
                disabled: false,
            };
        default:
            throw new Error("Invalid Action type.");
    }
};
