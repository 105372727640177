import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Stack from 'react-bootstrap/Stack';
import { userPoolClientsReducer, initialState } from '../UserPoolClients.reducer';
import { UserPoolClientAction } from '../UserPoolClients.actions';
import { IUserPoolClientDetail } from '../UserPoolClients.types';
import { getUserPoolClientDetails } from '../UserPoolClients.service';

const UserPoolClientDetails = (): JSX.Element => {
    var [state, dispatch] = React.useReducer(userPoolClientsReducer, initialState);

    const updateUserPoolId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: UserPoolClientAction.UPDATE_USER_POOL_ID, userPoolId: e.currentTarget.value });
    };

    const updateClientId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: UserPoolClientAction.UPDATE_CLIENT_ID, clientId: e.currentTarget.value });
    };

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: UserPoolClientAction.SUBMIT_FORM });
        getUserPoolClientDetails(state.userPoolId, state.clientId, dispatch);
    };

    return(
        <Stack gap={3}>
            <h2>Details</h2>
            <Form>
                <Stack gap={3}>
                    <Form.Group>
                        <Form.Control
                            id='userPoolIdInput'
                            type='string'
                            placeholder='Enter User Pool Id'
                            value={state.userPoolId}
                            onChange={updateUserPoolId}
                            disabled={state.disabled}
                            />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            id='userPoolClientIdInput'
                            type='string'
                            placeholder='Enter Client Id'
                            value={state.clientId}
                            onChange={updateClientId}
                            disabled={state.disabled}
                            />
                    </Form.Group>
                    <Form.Group>
                        <Button
                            variant='primary'
                            type='submit'
                            onClick={submitForm}
                            disabled={state.disabled}
                            >
                            Search
                        </Button>
                    </Form.Group>
                </Stack>
            </Form>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Client Id</th>
                        <th>Client Name</th>
                        <th>Allowed OAuth Flows</th>
                        <th>Allowed OAuth Scopes</th>
                        <th>Allows OAuth Workflow?</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {state.searchResultDetail && (
                        <tr>
                            <td key={`userPool-clientId`}>{state.searchResultDetail.clientId}</td>
                            <td key={`userPool-clientName`}>{state.searchResultDetail.clientName}</td>
                            <td key={`userPool-allowedOAuthFlows`}>{state.searchResultDetail.allowedOAuthFlows.join(' ')}</td>
                            <td key={`userPool-allowedOAuthScopes`}>{state.searchResultDetail.allowedOAuthScopes.join(' ')}</td>
                            <td key={`userPool-allowedOAuthFlowsUserPoolClient`}>{state.searchResultDetail.allowedOAuthFlowsUserPoolClient.toString()}</td>
                            <td key={`userPool-editCol`}><Button>Edit</Button></td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Stack>
    );
};

export default UserPoolClientDetails;