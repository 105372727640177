import { IMicroservice } from "./Microservices.types";

export enum MicroserviceAction {
    CHANGE_MICROSERVICE_NAME = 'CHANGE_MICROSERVICE_NAME',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_GET_RESPONSE = 'RECEIVE_GET_RESPONSE',
    CREATE_MICROSERVICE = 'CREATE_MICROSERVICE',
    EDIT_MICROSERVICE = 'EDIT_MICROSERVICE',
    DELETE_MICROSERVICE = 'DELETE_MICROSERVICE',
    CLOSE_CREATE_MODAL = 'CLOSE_CREATE_MODAL',
    CLOSE_EDIT_MODAL = 'CLOSE_EDIT_MODAL',
    SAVE_EDIT = 'SAVE_EDIT',
};

export type MicroserviceActionType = IChangeMicroserviceNameAction
    | ISubmitFormAction
    | IReceiveResponseAction
    | ICreateMicroserviceAction
    | IEditMicroserviceAction
    | IDeleteMicroserviceAction
    | ICloseCreateModalAction
    | ICloseEditModalAction
    | ISaveEditAction;

export interface IChangeMicroserviceNameAction {
    type: MicroserviceAction.CHANGE_MICROSERVICE_NAME,
    microserviceName: string,
};

export interface ISubmitFormAction {
    type: MicroserviceAction.SUBMIT_FORM,
};

export interface IReceiveResponseAction {
    type: MicroserviceAction.RECEIVE_GET_RESPONSE,
    searchResult: IMicroservice[],
};

export interface ICreateMicroserviceAction {
    type: MicroserviceAction.CREATE_MICROSERVICE,
};

export interface IEditMicroserviceAction {
    type: MicroserviceAction.EDIT_MICROSERVICE,
    microserviceId: number,
};

export interface IDeleteMicroserviceAction {
    type: MicroserviceAction.DELETE_MICROSERVICE,
};

export interface ICloseCreateModalAction {
    type: MicroserviceAction.CLOSE_CREATE_MODAL,
};

export interface ICloseEditModalAction {
    type: MicroserviceAction.CLOSE_EDIT_MODAL,
};

export interface ISaveEditAction {
    type: MicroserviceAction.SAVE_EDIT,
};
