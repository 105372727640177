import { IUserPoolClient, IUserPoolClientDetail } from "./UserPoolClients.types";

export enum UserPoolClientAction {
    UPDATE_USER_POOL_ID = 'UPDATE_USER_POOL_ID',
    UPDATE_CLIENT_ID = 'UPDATE_CLIENT_ID',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_RESPONSE_LOOKUP = 'RECEIVE_RESPONSE_LOOKUP',
    RECEIVE_RESPONSE_DETAIL = 'RECEIVE_RESPONSE_DETAIL',
};

export type UserPoolClientActionType = IUpdateUserPoolIdAction
    | IUpdateClientIdAction
    | ISubmitFormAction
    | IReceiveResponseLookupAction
    | IRecieveResponseDetailsAction;

export interface IUpdateUserPoolIdAction {
    type: UserPoolClientAction.UPDATE_USER_POOL_ID,
    userPoolId: string,
};

export interface IUpdateClientIdAction {
    type: UserPoolClientAction.UPDATE_CLIENT_ID,
    clientId: string,
};

export interface ISubmitFormAction {
    type: UserPoolClientAction.SUBMIT_FORM,
};

export interface IReceiveResponseLookupAction {
    type: UserPoolClientAction.RECEIVE_RESPONSE_LOOKUP,
    searchResultLookup: IUserPoolClient[],
};

export interface IRecieveResponseDetailsAction {
    type: UserPoolClientAction.RECEIVE_RESPONSE_DETAIL,
    searchResultDetail: IUserPoolClientDetail | undefined,
};
