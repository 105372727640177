const { REACT_APP_BASE_URL } = process.env;

export const getVendorDealerLocationByVendorId = (vendorId: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}vendordealerlocations/getrecordsbyvendorid?vendorId=${vendorId}`);
};

export const getVendorDealerLocationByVendorName = (vendorName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}vendordealerlocations/searchrecordsbyvendorname?nameSubstring=${vendorName}`);
};

export const getVendorDealerLocationByDealerId = (dealerId: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}vendordealerlocations/getrecordsbydealerid?dealerId=${dealerId}`);
};

export const getVendorDealerLocationByDealerName = (dealerName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}vendordealerlocations/searchrecordsbydealername?nameSubstring=${dealerName}`);
};

export const getAllVendorDealerLocations = (): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}vendordealerlocations/getallvendordealerlocations`);
}

export const deleteVendorDealerLocationById = (vendorDealerLocationId: number): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}vendordealerlocations/deletevendordealerlocationbyid?vendorDealerLocationId=${vendorDealerLocationId}`, {
        method: 'DELETE',
    });
}