import Microservices from "./Microservices";
import { IMicroservice } from "./Microservices.types";
import { getAllMicroservices } from "./Microservices.service";

export type {
    IMicroservice,
};

export {
    getAllMicroservices,
};

export default Microservices;