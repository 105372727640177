const { REACT_APP_BASE_URL } = process.env;

export const getAllMicroservices = (): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}microservices/getallmicroservices`);
};

export const searchMicroserviceByName = (microserviceName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}microservices/searchmicroservicesbyname?nameSubstring=${microserviceName}`);
};

export const deleteMicroserviceById = (microserviceId: number): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}microservices/deletemicroservicebyid?microserviceId=${microserviceId}`, {
        method: 'DELETE',
    });
};
