import { IVendor } from "./Vendors.types";

export enum VendorAction {
    CHANGE_VENDOR_ID = 'CHANGE_VENDOR_ID',
    CHANGE_VENDOR_NAME = 'CHANGE_VENDOR_NAME',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_RESPONSE = 'RECEIVE_RESPONSE',
    CHANGE_VENDOR_SEARCH_CHOICE = 'CHANGE_VENDOR_SEARCH_CHOICE',
    CREATE_VENDOR = 'CREATE_VENDOR',
    EDIT_VENDOR = 'EDIT_VENDOR',
    DELETE_VENDOR = 'DELETE_VENDOR',
    CLOSE_CREATE_MODAL = 'CLOSE_CREATE_MODAL',
    CLOSE_EDIT_MODAL = 'CLOSE_EDIT_MODAL',
    SAVE_EDIT = 'SAVE_EDIT',
};

export enum VendorSearchChoice {
    SEARCH_BY_ID = 'SEARCH_BY_ID',
    SEARCH_BY_NAME = 'SEARCH_BY_NAME',
};

export type VendorActionType = IChangeVendorIdAction
    | IChangeVendorNameAction
    | ISubmitFormAction
    | IReceiveResponseAction
    | IChangeVendorSearchAction
    | ICreateVendorAction
    | IEditVendorAction
    | IDeleteVendorAction
    | ICloseCreateModalAction
    | ICloseEditModalAction
    | ISaveEditAction;

export interface IChangeVendorIdAction {
    type: VendorAction.CHANGE_VENDOR_ID,
    vendorId: string,
};

export interface IChangeVendorNameAction {
    type: VendorAction.CHANGE_VENDOR_NAME,
    vendorName: string,
};

export interface ISubmitFormAction {
    type: VendorAction.SUBMIT_FORM,
};

export interface IReceiveResponseAction {
    type: VendorAction.RECEIVE_RESPONSE,
    searchResult: IVendor[],
};

export interface IChangeVendorSearchAction {
    type: VendorAction.CHANGE_VENDOR_SEARCH_CHOICE,
    searchType: VendorSearchChoice,
};

export interface ICreateVendorAction {
    type: VendorAction.CREATE_VENDOR,
};

export interface IEditVendorAction {
    type: VendorAction.EDIT_VENDOR,
    vendorPrimaryKeyId: number
};

export interface IDeleteVendorAction {
    type: VendorAction.DELETE_VENDOR,
};

export interface ICloseCreateModalAction {
    type: VendorAction.CLOSE_CREATE_MODAL,
};

export interface ICloseEditModalAction {
    type: VendorAction.CLOSE_EDIT_MODAL,
};

export interface ISaveEditAction {
    type: VendorAction.SAVE_EDIT,
};
