import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { ICreateMicroserviceProps } from '../Microservices.types';
import { createMicroserviceReducer, initialState } from './CreateMicroserviceModal.reducer';
import { CreateMicroserviceAction } from './CreateMicroserviceModal.actions';
import { createMicroservice } from './CreateMicroserviceModal.service';

const CreateMicroserviceModal = (props: ICreateMicroserviceProps): JSX.Element => {
    const [state, dispatch] = React.useReducer(createMicroserviceReducer, initialState);

    const updateMicroserviceName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: CreateMicroserviceAction.CHANGE_MICROSERVICE_NAME, microserviceName: e.target.value });
    };

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: CreateMicroserviceAction.SUBMIT_FORM });
        createMicroservice(state.microserviceName)
            .then((value: Response) => dispatch({ type: CreateMicroserviceAction.RECEIVE_RESPONSE }))
            .then((value: void) => props.onHide())
            .catch((reason: any) => dispatch({ type: CreateMicroserviceAction.RECEIVE_RESPONSE }));
        props.onHide();
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop='static'
            keyboard={false}
            >
            <Modal.Header closeButton={true}>
                <Modal.Title>Create Microservice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Stack gap={3}>
                        <Form.Group>
                            <Form.Label>Microservice Name</Form.Label>
                            <Form.Control
                                id='createMicroserviceNameInput'
                                type='string'
                                placeholder='Create Service Name'
                                value={state.microserviceName}
                                onChange={updateMicroserviceName}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                >
                                Create
                            </Button>
                        </Stack>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateMicroserviceModal;