import React from 'react';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import VendorDealerLocationDataTable from './DataTable';
import { VendorDealerLocationAction, VendorDealerLocationSearchChoice } from './VendorDealerLocations.actions';
import { initialState, vendorDealerLocationReducer } from './VendorDealerLocations.reducer';
import { deleteVendorDealerLocationById, getAllVendorDealerLocations, getVendorDealerLocationByDealerId, getVendorDealerLocationByDealerName, getVendorDealerLocationByVendorId, getVendorDealerLocationByVendorName } from './VendorDealerLocations.service';
import { IVendorDealerLocation } from './VendorDealerLocations.types';
import CreateVendorDealerLocationModal from './Create';

const VendorDealerLocations = (): JSX.Element => {
    const [state, dispatch] = React.useReducer(vendorDealerLocationReducer, initialState);

    React.useEffect(() => {
        _getAllVendorDealerLocations();
    }, []);

    React.useEffect(() => {
        _getAllVendorDealerLocations();
    }, [state.createFlag, state.deleteFlag, state.editDealerLocationId]);

    const _getAllVendorDealerLocations = (): void => {
        getAllVendorDealerLocations()
            .then((value: Response) => value.json())
            .then((searchResult: IVendorDealerLocation[]) => dispatch({ type: VendorDealerLocationAction.RECEIVE_RESPONSE, searchResult: searchResult }));
    }

    const updateVendorId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: VendorDealerLocationAction.CHANGE_VENDOR_DEALER_LOCATION_VENDOR_ID, vendorId: e.target.value});
    };

    const updateVendorName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: VendorDealerLocationAction.CHANGE_VENDOR_DEALER_LOCATION_VENDOR_NAME, vendorName: e.target.value });
    };

    const updateDealerId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: VendorDealerLocationAction.CHANGE_VENDOR_DEALER_LOCATION_DEALER_ID, dealerId: e.target.value });
    };

    const updateDealerName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: VendorDealerLocationAction.CHANGE_VENDOR_DEALER_LOCATION_DEALER_NAME, dealerName: e.target.value });
    };

    const updateSearchBy = (choice: VendorDealerLocationSearchChoice): void => {
        dispatch({ type: VendorDealerLocationAction.CHANGE_VENDOR_DEALER_LOCATION_SEARCH_CHOICE, searchType: choice})
    }

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: VendorDealerLocationAction.SUBMIT_FORM });
        switch (state.searchType) {
            case VendorDealerLocationSearchChoice.SEARCH_BY_VENDOR_ID:
                console.log(state.vendorId);
                getVendorDealerLocationByVendorId(state.vendorId)
                    .then((value: Response) => value.json())
                    .then((searchResult: IVendorDealerLocation[]) => dispatch({ type: VendorDealerLocationAction.RECEIVE_RESPONSE, searchResult: searchResult }))
                    .catch((reason: any) => dispatch({ type: VendorDealerLocationAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            case VendorDealerLocationSearchChoice.SEARCH_BY_VENDOR_NAME:
                getVendorDealerLocationByVendorName(state.vendorName)
                    .then((value: Response) => value.json())
                    .then((searchResult: IVendorDealerLocation[]) => dispatch({ type: VendorDealerLocationAction.RECEIVE_RESPONSE, searchResult: searchResult }))
                    .catch((reason: any) => dispatch({ type: VendorDealerLocationAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            case VendorDealerLocationSearchChoice.SEARCH_BY_DEALER_ID:
                getVendorDealerLocationByDealerId(state.dealerId)
                    .then((value: Response) => value.json())
                    .then((searchResult: IVendorDealerLocation[]) => dispatch({ type: VendorDealerLocationAction.RECEIVE_RESPONSE, searchResult: searchResult }))
                    .catch((reason: any) => dispatch({ type: VendorDealerLocationAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            case VendorDealerLocationSearchChoice.SEARCH_BY_DEALER_NAME:
                getVendorDealerLocationByDealerName(state.dealerName)
                    .then((value: Response) => value.json())
                    .then((searchResult: IVendorDealerLocation[]) => dispatch({ type: VendorDealerLocationAction.RECEIVE_RESPONSE, searchResult: searchResult }))
                    .catch((reason: any) => dispatch({ type: VendorDealerLocationAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            default:
                throw new Error('Invalid search choice.');
        }
    };

    const resetForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        _getAllVendorDealerLocations();
    };

    const onDeleteVendorDealerLocation = (dealerLocationId: number): void => {
        deleteVendorDealerLocationById(dealerLocationId)
            .then((value: Response) => dispatch({ type: VendorDealerLocationAction.DELETE_VENDOR_DEALER_LOCATION }))
            .catch((reason: any) => dispatch({ type: VendorDealerLocationAction.DELETE_VENDOR_DEALER_LOCATION }));
    };

    const onHideCreateModal = (): void => {
        dispatch({ type: VendorDealerLocationAction.CLOSE_CREATE_MODAL });
    };

    const _createVendorDealerLocation = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: VendorDealerLocationAction.CREATE_VENDOR_DEALER_LOCATION });
    };

    return (
        <Stack gap={3}>
            <h1>Vendor Dealer Location Lookup</h1>
            <Form>
                <Stack gap={3}>
                    <Form.Group>
                        <Form.Check
                            id='dealerLocationSearchByVendorIdRadio'
                            type='radio'
                            name='searchBy'
                            label={`Search By Vendor ID`}
                            onChange={_ => updateSearchBy(VendorDealerLocationSearchChoice.SEARCH_BY_VENDOR_ID)}
                            checked={state.searchType === VendorDealerLocationSearchChoice.SEARCH_BY_VENDOR_ID}
                            inline={true}
                            />
                        <Form.Check
                            id='dealerLocationSearchByVendorNameRadio'
                            type='radio'
                            name='searchBy'
                            label={`Search By Vendor Name`}
                            onChange={_ => updateSearchBy(VendorDealerLocationSearchChoice.SEARCH_BY_VENDOR_NAME)}
                            checked={state.searchType === VendorDealerLocationSearchChoice.SEARCH_BY_VENDOR_NAME}
                            inline={true}
                            />
                        <Form.Check
                            id='dealerLocationSearchByDealerIdRadio'
                            type='radio'
                            name='searchBy'
                            label={`Search By Dealer ID`}
                            onChange={_ => updateSearchBy(VendorDealerLocationSearchChoice.SEARCH_BY_DEALER_ID)}
                            checked={state.searchType === VendorDealerLocationSearchChoice.SEARCH_BY_DEALER_ID}
                            inline={true}
                            />
                        <Form.Check
                            id='dealerLocationSearchByDealerNameRadio'
                            type='radio'
                            name='searchBy'
                            label={`Search By Dealer Name`}
                            onChange={_ => updateSearchBy(VendorDealerLocationSearchChoice.SEARCH_BY_DEALER_NAME)}
                            checked={state.searchType === VendorDealerLocationSearchChoice.SEARCH_BY_DEALER_NAME}
                            inline={true}
                            />
                    </Form.Group>
                    {state.searchType === VendorDealerLocationSearchChoice.SEARCH_BY_VENDOR_ID && (
                        <Form.Group>
                            <Form.Label>Vendor ID</Form.Label>
                            <Form.Control
                                id='vendorIdInput'
                                type='string'
                                placeholder='Enter Vendor ID'
                                value={state.vendorId}
                                onChange={updateVendorId}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    {state.searchType === VendorDealerLocationSearchChoice.SEARCH_BY_VENDOR_NAME && (
                        <Form.Group>
                            <Form.Label>Vendor Name</Form.Label>
                            <Form.Control
                                id='vendorNameInput'
                                type='string'
                                placeholder='Enter Vendor Name'
                                value={state.vendorName}
                                onChange={updateVendorName}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    {state.searchType === VendorDealerLocationSearchChoice.SEARCH_BY_DEALER_ID && (
                        <Form.Group>
                            <Form.Label>Dealer ID</Form.Label>
                            <Form.Control
                                id='dealerIdInput'
                                type='string'
                                placeholder='Enter Dealer ID'
                                value={state.dealerId}
                                onChange={updateDealerId}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    {state.searchType === VendorDealerLocationSearchChoice.SEARCH_BY_DEALER_NAME && (
                        <Form.Group>
                            <Form.Label>Dealer Name</Form.Label>
                            <Form.Control
                                id='dealerNameInput'
                                type='string'
                                placeholder='Enter Dealer Name'
                                value={state.dealerName}
                                onChange={updateDealerName}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Stack gap={3} direction='horizontal'>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                disabled={state.disabled}
                                >
                                Search
                            </Button>
                            <Button
                                variant='secondary'
                                onClick={resetForm}
                                >
                                Reset
                            </Button>
                            <Button
                                variant='success'
                                onClick={_createVendorDealerLocation}
                                >
                                Create New Association
                            </Button>
                        </Stack>
                    </Form.Group>
                </Stack>
            </Form>
            <VendorDealerLocationDataTable
                data={state.searchResult}
                onDelete={onDeleteVendorDealerLocation}
                />
            <CreateVendorDealerLocationModal
                show={state.showCreateModal}
                onHide={onHideCreateModal}
                />
        </Stack>
    );
};

export default VendorDealerLocations;