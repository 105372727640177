import { VendorDealerLocationAction, VendorDealerLocationActionType, VendorDealerLocationSearchChoice } from './VendorDealerLocations.actions';
import { IVendorDealerLocation } from './VendorDealerLocations.types';

export interface IVendorDealerLocationState {
    vendorId: string,
    vendorName: string,
    dealerId: string,
    dealerName: string,
    disabled: boolean,
    searchResult: IVendorDealerLocation[],
    searchType: VendorDealerLocationSearchChoice,
    showCreateModal: boolean,
    showEditModal: boolean,
    editDealerLocationId: number,
    createFlag: boolean,
    deleteFlag: boolean,
};

export const initialState: IVendorDealerLocationState = {
    vendorId: '',
    vendorName: '',
    dealerId: '',
    dealerName: '',
    disabled: false,
    searchResult: [],
    searchType: VendorDealerLocationSearchChoice.SEARCH_BY_VENDOR_ID,
    showCreateModal: false,
    showEditModal: false,
    editDealerLocationId: 0,
    createFlag: false,
    deleteFlag: false,
};

export const vendorDealerLocationReducer = (state: IVendorDealerLocationState, action: VendorDealerLocationActionType): IVendorDealerLocationState => {
    switch (action.type) {
        case VendorDealerLocationAction.CHANGE_VENDOR_DEALER_LOCATION_VENDOR_ID:
            return {
                ...state,
                vendorId: action.vendorId,
            };
        case VendorDealerLocationAction.CHANGE_VENDOR_DEALER_LOCATION_VENDOR_NAME:
        return {
            ...state,
            vendorName: action.vendorName,
        };
        case VendorDealerLocationAction.CHANGE_VENDOR_DEALER_LOCATION_DEALER_ID:
            return {
                ...state,
                dealerId: action.dealerId,
            };
        case VendorDealerLocationAction.CHANGE_VENDOR_DEALER_LOCATION_DEALER_NAME:
            return {
                ...state,
                dealerName: action.dealerName,
            };
        case VendorDealerLocationAction.CHANGE_VENDOR_DEALER_LOCATION_SEARCH_CHOICE:
            return {
                ...state,
                searchType: action.searchType,
            };
        case VendorDealerLocationAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case VendorDealerLocationAction.RECEIVE_RESPONSE:
            return {
                ...state,
                disabled: false,
                searchResult: action.searchResult,
            };
        case VendorDealerLocationAction.CREATE_VENDOR_DEALER_LOCATION:
            return {
                ...state,
                showCreateModal: true,
            };
        case VendorDealerLocationAction.DELETE_VENDOR_DEALER_LOCATION:
            return {
                ...state,
                deleteFlag: !state.deleteFlag,
            };
        case VendorDealerLocationAction.CLOSE_CREATE_MODAL:
            return {
                ...state,
                showCreateModal: false,
                createFlag: !state.createFlag,
            };
        case VendorDealerLocationAction.CLOSE_EDIT_MODAL:
            return {
                ...state,
                showEditModal: false,
                editDealerLocationId: 0,
            };
        default:
            throw new Error('Invalid action type.');
    }
};