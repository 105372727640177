import React from 'react';
import { UserPoolClientAction, UserPoolClientActionType } from "./UserPoolClients.actions";
import { IUserPoolClient, IUserPoolClientDetail } from "./UserPoolClients.types";

const { REACT_APP_BASE_URL } = process.env;

export const getUserPoolClients = (userPoolId: string, dispatch: React.Dispatch<UserPoolClientActionType>): void => {
    fetch(`${REACT_APP_BASE_URL}api/Aws/GetUserPoolClients?userPoolId=${userPoolId}`)
        .then((value: Response) => value.json())
        .then((searchResult: IUserPoolClient[]) => dispatch({ type: UserPoolClientAction.RECEIVE_RESPONSE_LOOKUP, searchResultLookup: searchResult }))
        .catch((reason: any) => dispatch({ type: UserPoolClientAction.RECEIVE_RESPONSE_LOOKUP, searchResultLookup: [] }));
};

export const getUserPoolClientDetails = (userPoolId: string, clientId: string, dispatch: React.Dispatch<UserPoolClientActionType>): void => {
    fetch(`${REACT_APP_BASE_URL}api/Aws/GetUserPoolClientDetails?userPoolId=${userPoolId}&clientId=${clientId}`)
        .then((value: Response) => value.json())
        .then((searchResult: IUserPoolClientDetail) => dispatch({ type: UserPoolClientAction.RECEIVE_RESPONSE_DETAIL, searchResultDetail: searchResult }))
        .catch((reason: any) => dispatch({ type: UserPoolClientAction.RECEIVE_RESPONSE_DETAIL, searchResultDetail: undefined }));
}