const { REACT_APP_BASE_URL } = process.env;

export const getMicroserviceById = (microserviceId: number): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}microservices/getmicroservicebyid?microserviceId=${microserviceId}`);
};

export const updateMicroserviceName = (microserviceId: number, microserviceName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}microservices/updatemicroservice?microserviceId=${microserviceId}`, {
        method: 'PATCH',
        body: JSON.stringify({
            microserviceName: microserviceName,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
}
