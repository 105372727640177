import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { IEditDealerLocationProps, IDealerLocation } from '../DealerLocations.types';
import { editDealerLocationReducer, initialState } from './EditDealerLocationModal.reducer';
import { getDealerLocationById, updateDealerLocationName } from './EditDealerLocationModal.service';
import { EditDealerLocationAction } from './EditDealerLocationModal.actions';

const EditDealerLocationModal = (props: IEditDealerLocationProps): JSX.Element => {
    const [state, dispatch] = React.useReducer(editDealerLocationReducer, initialState);

    React.useEffect(() => {
        if (props.dealerLocationId === 0) {
            return;
        }

        getDealerLocationById(props.dealerLocationId)
            .then((value: Response) => value.json())
            .then((searchResult: IDealerLocation) => dispatch({ type: EditDealerLocationAction.RECEIVE_GET_RESPONSE, searchResult: searchResult }))
            .catch((reason: any) => dispatch({ type: EditDealerLocationAction.RECEIVE_GET_RESPONSE, searchResult: undefined }));
    }, [props.dealerLocationId]);

    const _updateDealerLocationName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: EditDealerLocationAction.CHANGE_DEALER_LOCATION_NAME, dealerLocationName: e.target.value });
    };

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: EditDealerLocationAction.SUBMIT_FORM });

        updateDealerLocationName(props.dealerLocationId, state.dealerLocationName)
            .then((value: Response) => dispatch({ type: EditDealerLocationAction.RECEIVE_PATCH_RESPONSE }))
            .then((value: void) => props.onHide())
            .catch((reason: any) => dispatch({ type: EditDealerLocationAction.RECEIVE_PATCH_RESPONSE }));
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop='static'
            keyboard={false}
            >
            <Modal.Header closeButton={true}>
                <Modal.Title>Edit DealerLocation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Stack gap={3}>
                        <Form.Group>
                            <Form.Label>Location Id</Form.Label>
                            <Form.Control
                                id='editDealerLocationIdReadOnlyInput'
                                type='number'
                                value={state.dealerLocation?.locationId || 0}
                                disabled={true}
                                />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Location Name</Form.Label>
                            <Form.Control
                                id='editDealerLocationNameInput'
                                type='string'
                                placeholder='Edit Service Name'
                                value={state.dealerLocationName}
                                onChange={_updateDealerLocationName}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                        <Form.Group>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                >
                                Edit
                            </Button>
                        </Form.Group>
                        </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditDealerLocationModal;