import { IDealer } from "../../Dealers";
import { CreateDealerLocationAction, CreateDealerLocationActionType } from "./CreateDealerLocationModal.actions";

export interface ICreateDealerLocationState {
    dealerOptions: IDealer[]
    dealerLocationName: string,
    disabled: boolean,
    selectedDealerPrimaryKeyId: number,
    locationId: number,
};

export const initialState: ICreateDealerLocationState = {
    dealerOptions: [],
    dealerLocationName: '',
    disabled: false,
    selectedDealerPrimaryKeyId: 0,
    locationId: 0,
};

export const createDealerLocationReducer = (state: ICreateDealerLocationState, action: CreateDealerLocationActionType): ICreateDealerLocationState => {
    switch (action.type) {
        case CreateDealerLocationAction.CHANGE_DEALER_LOCATION_NAME:
            return {
                ...state,
                dealerLocationName: action.dealerLocationName,
            };
        case CreateDealerLocationAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case CreateDealerLocationAction.RECEIVE_RESPONSE:
            return {
                ...state,
                disabled: false,
            };
        case CreateDealerLocationAction.RECEIVE_DEALERS_RESPONSE:
            return {
                ...state,
                dealerOptions: action.dealerOptions,
                selectedDealerPrimaryKeyId: action.dealerOptions[0].id,
            };
        case CreateDealerLocationAction.SELECT_DEALER:
            return {
                ...state,
                selectedDealerPrimaryKeyId: action.dealerPrimaryKeyId,
            };
        case CreateDealerLocationAction.CHANGE_LOCATION_ID:
            return {
                ...state,
                locationId: action.locationId,
            };
        default:
            throw new Error("Invalid Action type.");
    }
};
