import { DealerAction, DealerActionType, DealerSearchChoice } from './Dealers.actions';
import { IDealer } from './Dealers.types';

export interface IDealerState {
    dealerId: string,
    dealerName: string,
    disabled: boolean,
    searchResult: IDealer[],
    searchType: DealerSearchChoice,
    showCreateModal: boolean,
    showEditModal: boolean,
    editDealerPrimaryKeyId: number,
    createFlag: boolean,
    deleteFlag: boolean,
};

export const initialState: IDealerState = {
    dealerId: '',
    dealerName: '',
    disabled: false,
    searchResult: [],
    searchType: DealerSearchChoice.SEARCH_BY_ID,
    showCreateModal: false,
    showEditModal: false,
    editDealerPrimaryKeyId: 0,
    createFlag: false,
    deleteFlag: false,
};

export const dealerReducer = (state: IDealerState, action: DealerActionType): IDealerState => {
    switch (action.type) {
        case DealerAction.CHANGE_DEALER_ID:
            return {
                ...state,
                dealerId: action.dealerId,
            };
        case DealerAction.CHANGE_DEALER_NAME:
            return {
                ...state,
                dealerName: action.dealerName,
            };
        case DealerAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case DealerAction.RECEIVE_RESPONSE:
            return {
                ...state,
                disabled: false,
                searchResult: action.searchResult,
            };
        case DealerAction.CHANGE_DEALER_SEARCH_CHOICE:
            return {
                ...state,
                searchType: action.searchType,
            };
        case DealerAction.CREATE_DEALER:
            return {
                ...state,
                showCreateModal: true,
            };
        case DealerAction.EDIT_DEALER:
            return {
                ...state,
                showEditModal: true,
                editDealerPrimaryKeyId: action.dealerPrimaryKeyId,
            };
        case DealerAction.DELETE_DEALER:
            return {
                ...state,
                deleteFlag: !state.deleteFlag,
            };
        case DealerAction.CLOSE_CREATE_MODAL:
            return {
                ...state,
                showCreateModal: false,
                createFlag: !state.createFlag,
            };
        case DealerAction.CLOSE_EDIT_MODAL:
            return {
                ...state,
                showEditModal: false,
                editDealerPrimaryKeyId: 0,
            };
        default:
            throw new Error('Invalid action type.');
    }
};