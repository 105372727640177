import { CreateMicroserviceAction, CreateMicroserviceActionType } from "./CreateMicroserviceModal.actions";

export interface ICreateMicroserviceState {
    microserviceName: string,
    disabled: boolean,
};

export const initialState: ICreateMicroserviceState = {
    microserviceName: '',
    disabled: false,
};

export const createMicroserviceReducer = (state: ICreateMicroserviceState, action: CreateMicroserviceActionType): ICreateMicroserviceState => {
    switch (action.type) {
        case CreateMicroserviceAction.CHANGE_MICROSERVICE_NAME:
            return {
                ...state,
                microserviceName: action.microserviceName,
            };
        case CreateMicroserviceAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case CreateMicroserviceAction.RECEIVE_RESPONSE:
            return {
                ...state,
                disabled: false,
            };
        default:
            throw new Error("Invalid Action type.");
    }
};
