const { REACT_APP_BASE_URL } = process.env;

export const getDealerByKey = (dealerPrimaryKeyId: number): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}dealers/getdealerbykey?dealerPrimaryKeyId=${dealerPrimaryKeyId}`);
};

export const updateDealerName = (dealerPrimaryKeyId: number, dealerId: string, dealerName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}dealers/updatedealer?dealerPrimaryKeyId=${dealerPrimaryKeyId}`, {
        method: 'PATCH',
        body: JSON.stringify({
            dealerId: dealerId,
            dealerName: dealerName,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
}
