import { IDealer } from "../Dealers.types";

export enum EditDealerAction {
    CHANGE_DEALER_ID = 'CHANGE_DEALER_ID',
    CHANGE_DEALER_NAME = 'CHANGE_DEALER_NAME',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_GET_RESPONSE = 'RECEIVE_GET_RESPONSE',
    RECEIVE_PATCH_RESPONSE = 'RECEIVE_PATCH_RESPONSE',
};

export type EditDealerActionType = IChangeDealerNameAction
    | IChangeDealerIdAction
    | ISubmitFormAction
    | IReceiveGetResponseAction
    | IReceivePatchResponseAction;

export interface IChangeDealerIdAction {
    type: EditDealerAction.CHANGE_DEALER_ID,
    dealerId: string,
}

export interface IChangeDealerNameAction {
    type: EditDealerAction.CHANGE_DEALER_NAME,
    dealerName: string,
};

export interface ISubmitFormAction {
    type: EditDealerAction.SUBMIT_FORM,
};

export interface IReceiveGetResponseAction {
    type: EditDealerAction.RECEIVE_GET_RESPONSE,
    searchResult: IDealer | undefined,
};

export interface IReceivePatchResponseAction {
    type: EditDealerAction.RECEIVE_PATCH_RESPONSE,
}
