import { IDealer } from "./Dealers.types";

export enum DealerAction {
    CHANGE_DEALER_ID = 'CHANGE_DEALER_ID',
    CHANGE_DEALER_NAME = 'CHANGE_DEALER_NAME',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_RESPONSE = 'RECEIVE_RESPONSE',
    CHANGE_DEALER_SEARCH_CHOICE = 'CHANGE_DEALER_SEARCH_CHOICE',
    CREATE_DEALER = 'CREATE_DEALER',
    EDIT_DEALER = 'EDIT_DEALER',
    DELETE_DEALER = 'DELETE_DEALER',
    CLOSE_CREATE_MODAL = 'CLOSE_CREATE_MODAL',
    CLOSE_EDIT_MODAL = 'CLOSE_EDIT_MODAL',
    SAVE_EDIT = 'SAVE_EDIT',
};

export enum DealerSearchChoice {
    SEARCH_BY_ID = 'SEARCH_BY_ID',
    SEARCH_BY_NAME = 'SEARCH_BY_NAME',
};

export type DealerActionType = IChangeDealerIdAction
    | IChangeDealerNameAction
    | ISubmitFormAction
    | IReceiveResponseAction
    | IChangeDealerSearchAction
    | ICreateDealerAction
    | IEditDealerAction
    | IDeleteDealerAction
    | ICloseCreateModalAction
    | ICloseEditModalAction
    | ISaveEditAction;

export interface IChangeDealerIdAction {
    type: DealerAction.CHANGE_DEALER_ID,
    dealerId: string,
};

export interface IChangeDealerNameAction {
    type: DealerAction.CHANGE_DEALER_NAME,
    dealerName: string,
};

export interface ISubmitFormAction {
    type: DealerAction.SUBMIT_FORM,
};

export interface IReceiveResponseAction {
    type: DealerAction.RECEIVE_RESPONSE,
    searchResult: IDealer[],
};

export interface IChangeDealerSearchAction {
    type: DealerAction.CHANGE_DEALER_SEARCH_CHOICE,
    searchType: DealerSearchChoice,
};

export interface ICreateDealerAction {
    type: DealerAction.CREATE_DEALER,
};

export interface IEditDealerAction {
    type: DealerAction.EDIT_DEALER,
    dealerPrimaryKeyId: number
};

export interface IDeleteDealerAction {
    type: DealerAction.DELETE_DEALER,
};

export interface ICloseCreateModalAction {
    type: DealerAction.CLOSE_CREATE_MODAL,
};

export interface ICloseEditModalAction {
    type: DealerAction.CLOSE_EDIT_MODAL,
};

export interface ISaveEditAction {
    type: DealerAction.SAVE_EDIT,
};
