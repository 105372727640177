import DealerLocations from "./DealerLocations";
import { getDealerLocationByDealerId } from "./DealerLocations.service";
import { IDealerLocation } from "./DealerLocations.types";

export type {
    IDealerLocation,
};

export {
    getDealerLocationByDealerId,
}

export default DealerLocations;