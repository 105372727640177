import { IDealerLocation } from "../../DealerLocations";
import { IDealer } from "../../Dealers";
import { IVendor } from "../../Vendors";

export enum CreateVendorDealerLocationAction {
    CHANGE_VENDOR = 'CHANGE_VENDOR',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_RESPONSE = 'RECEIVE_RESPONSE',
    RECEIVE_DEALERS_RESPONSE = 'RECEIVE_DEALERS_RESPONSE',
    RECEIVE_VENDORS_RESPONSE = 'RECEIVE_VENDORS_RESPONSE',
    RECEIVE_DEALER_LOCATIONS_RESPONSE = 'RECEIVE_DEALER_LOCATIONS_RESPONSE',
    SELECT_DEALER = 'SELECT_DEALER',
    SELECT_DEALER_LOCATION = 'SELECT_DEALER_LOCATION',
};

export type CreateVendorDealerLocationActionType = IChangeVendorAction
    | ISubmitFormAction
    | IReceiveResponseAction
    | IReceiveDealersResponseAction
    | IReceiveDealerLocationsResponseAction
    | IReceiveVendorsResponseAction
    | ISelectDealerAction
    | ISelectDealerLocationAction;

export interface IChangeVendorAction {
    type: CreateVendorDealerLocationAction.CHANGE_VENDOR,
    vendorPrimaryKeyId: number,
};

export interface ISubmitFormAction {
    type: CreateVendorDealerLocationAction.SUBMIT_FORM,
};

export interface IReceiveResponseAction {
    type: CreateVendorDealerLocationAction.RECEIVE_RESPONSE,
};

export interface IReceiveDealerLocationsResponseAction {
    type: CreateVendorDealerLocationAction.RECEIVE_DEALER_LOCATIONS_RESPONSE,
    dealerLocationOptions: IDealerLocation[],
};

export interface IReceiveDealersResponseAction {
    type: CreateVendorDealerLocationAction.RECEIVE_DEALERS_RESPONSE,
    dealerOptions: IDealer[],
};

export interface IReceiveVendorsResponseAction {
    type: CreateVendorDealerLocationAction.RECEIVE_VENDORS_RESPONSE,
    vendorOptions: IVendor[],
};

export interface ISelectDealerAction {
    type: CreateVendorDealerLocationAction.SELECT_DEALER,
    dealerPrimaryKeyId: number,
};

export interface ISelectDealerLocationAction {
    type: CreateVendorDealerLocationAction.SELECT_DEALER_LOCATION,
    dealerLocationId: number,
};