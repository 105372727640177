import { IDealerLocation } from "../../DealerLocations";
import { IDealer } from "../../Dealers";
import { IVendor } from "../../Vendors";
import { CreateVendorDealerLocationAction, CreateVendorDealerLocationActionType } from "./CreateVendorDealerLocationModal.actions";

export interface ICreateVendorDealerLocationState {
    vendorOptions: IVendor[],
    dealerOptions: IDealer[],
    dealerLocationOptions: IDealerLocation[],
    disabled: boolean,
    selectedDealerPrimaryKeyId: number,
    selectedDealerLocationId: number,
    selectedVendorPrimaryKeyId: number,
};

export const initialState: ICreateVendorDealerLocationState = {
    vendorOptions: [],
    dealerOptions: [],
    dealerLocationOptions: [],
    disabled: false,
    selectedDealerPrimaryKeyId: 0,
    selectedDealerLocationId: 0,
    selectedVendorPrimaryKeyId: 0,
};

export const createVendorDealerLocationReducer = (state: ICreateVendorDealerLocationState, action: CreateVendorDealerLocationActionType): ICreateVendorDealerLocationState => {
    switch (action.type) {
        case CreateVendorDealerLocationAction.CHANGE_VENDOR:
            return {
                ...state,
                selectedVendorPrimaryKeyId: action.vendorPrimaryKeyId,
            };
        case CreateVendorDealerLocationAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case CreateVendorDealerLocationAction.RECEIVE_RESPONSE:
            return {
                ...state,
                disabled: false,
            };
        case CreateVendorDealerLocationAction.RECEIVE_DEALERS_RESPONSE:
            return {
                ...state,
                dealerOptions: action.dealerOptions,
                selectedDealerPrimaryKeyId: (action.dealerOptions && action.dealerOptions[0] && action.dealerOptions[0].id) || 0,
            };
        case CreateVendorDealerLocationAction.RECEIVE_DEALER_LOCATIONS_RESPONSE:
            return {
                ...state,
                dealerLocationOptions: action.dealerLocationOptions,
                selectedDealerLocationId: (action.dealerLocationOptions && action.dealerLocationOptions[0] && action.dealerLocationOptions[0].id) || 0,
            };
        case CreateVendorDealerLocationAction.RECEIVE_VENDORS_RESPONSE:
            return {
                ...state,
                vendorOptions: action.vendorOptions,
                selectedVendorPrimaryKeyId: (action.vendorOptions && action.vendorOptions[0] && action.vendorOptions[0].id) || 0,
            }
        case CreateVendorDealerLocationAction.SELECT_DEALER:
            return {
                ...state,
                selectedDealerPrimaryKeyId: action.dealerPrimaryKeyId,
            };
        case CreateVendorDealerLocationAction.SELECT_DEALER_LOCATION:
            return {
                ...state,
                selectedDealerLocationId: action.dealerLocationId,
            };
        default:
            throw new Error("Invalid Action type.");
    }
};
