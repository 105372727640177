import React from 'react';
import { DealerAction, DealerSearchChoice } from './Dealers.actions';
import { dealerReducer, initialState } from './Dealers.reducer';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { IDealer } from './Dealers.types';
import { getDealerById, getDealerByName, getAllDealers, deleteDealerById } from './Dealers.service';
import Stack from 'react-bootstrap/Stack';
import DealersDataTable from './DataTable';
import CreateDealerModal from './Create';
import EditDealerModal from './Edit';

const Dealers = (): JSX.Element => {
    const [state, dispatch] = React.useReducer(dealerReducer, initialState);

    React.useEffect(() => {
        _getAllDealers();
    }, []);

    React.useEffect(() => {
        _getAllDealers();
    }, [state.showEditModal, state.deleteFlag, state.createFlag])

    const _getAllDealers = (): void => {
        getAllDealers()
            .then((value: Response) => value.json())
            .then((searchResult: IDealer[]) => dispatch({ type: DealerAction.RECEIVE_RESPONSE, searchResult: searchResult }))
            .catch((reason: any) => dispatch({ type: DealerAction.RECEIVE_RESPONSE, searchResult: [] }));
    };

    const updateDealerId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: DealerAction.CHANGE_DEALER_ID, dealerId: e.target.value });
    };

    const updateDealerName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: DealerAction.CHANGE_DEALER_NAME, dealerName: e.target.value });
    };

    const updateSearchBy = (choice: DealerSearchChoice): void => {
        dispatch({ type: DealerAction.CHANGE_DEALER_SEARCH_CHOICE, searchType: choice });
    };

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: DealerAction.SUBMIT_FORM });
        switch (state.searchType) {
            case DealerSearchChoice.SEARCH_BY_ID:
                getDealerById(state.dealerId)
                    .then((value: Response) => value.json())
                    .then((searchResult: IDealer) => dispatch({ type: DealerAction.RECEIVE_RESPONSE, searchResult: [searchResult] }))
                    .catch((reason: any) => dispatch({ type: DealerAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            case DealerSearchChoice.SEARCH_BY_NAME:
                getDealerByName(state.dealerName)
                    .then((value: Response) => value.json())
                    .then((searchResult: IDealer[]) => dispatch({ type: DealerAction.RECEIVE_RESPONSE, searchResult: searchResult }))
                    .catch((reason: any) => dispatch({ type: DealerAction.RECEIVE_RESPONSE, searchResult: [] }));
                break;
            default:
                throw new Error('Invalid search choice.');
        }
    };

    const resetForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        _getAllDealers();
    };

    const onEditDealer = (dealerPrimaryKeyId: number): void => {
        dispatch({ type: DealerAction.EDIT_DEALER, dealerPrimaryKeyId: dealerPrimaryKeyId });
    };

    const onDeleteDealer = (dealerPrimaryKeyId: number): void => {
        deleteDealerById(dealerPrimaryKeyId)
            .then((value: Response) => dispatch({ type: DealerAction.DELETE_DEALER }))
            .catch((reason: any) => dispatch({ type: DealerAction.DELETE_DEALER }));
    };

    const onHideCreateModal = (): void => {
        dispatch({ type: DealerAction.CLOSE_CREATE_MODAL });
    };

    const onHideEditModal = (): void => {
        dispatch({ type: DealerAction.CLOSE_EDIT_MODAL });
    };

    const _createDealer = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: DealerAction.CREATE_DEALER });
    }

    return (
        <Stack gap={3}>
            <h1>Dealer Lookup</h1>
            <Form>
                <Stack gap={3}>
                    <Form.Group>
                        <Form.Check
                            id='dealerSearchByIdRadio'
                            type='radio'
                            name='searchBy'
                            label={`Search By ID`}
                            onChange={_ => updateSearchBy(DealerSearchChoice.SEARCH_BY_ID)}
                            checked={state.searchType === DealerSearchChoice.SEARCH_BY_ID}
                            inline={true}
                            />
                        <Form.Check
                            id='dealerSearchByNameRadio'
                            type='radio'
                            name='searchBy'
                            label={`Search By Name`}
                            onChange={_ => updateSearchBy(DealerSearchChoice.SEARCH_BY_NAME)}
                            checked={state.searchType === DealerSearchChoice.SEARCH_BY_NAME}
                            inline={true}
                            />
                    </Form.Group>
                    {state.searchType === DealerSearchChoice.SEARCH_BY_ID && (
                        <Form.Group>
                            <Form.Label>Dealer ID</Form.Label>
                            <Form.Control
                                id='dealerIdInput'
                                type='number'
                                placeholder='Enter Dealer ID'
                                value={state.dealerId}
                                onChange={updateDealerId}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    {state.searchType === DealerSearchChoice.SEARCH_BY_NAME && (
                        <Form.Group>
                            <Form.Label>Dealer Name</Form.Label>
                            <Form.Control
                                id='dealerNameInput'
                                type='string'
                                placeholder='Enter Dealer Name'
                                value={state.dealerName}
                                onChange={updateDealerName}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Stack gap={3} direction='horizontal'>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                disabled={state.disabled}
                                >
                                Search
                            </Button>
                            <Button
                                variant='secondary'
                                onClick={resetForm}
                                >
                                Reset
                            </Button>
                            <Button
                                variant='success'
                                onClick={_createDealer}
                                >
                                Create New Dealer
                            </Button>
                        </Stack>
                    </Form.Group>
                </Stack>
            </Form>
            <DealersDataTable
                data={state.searchResult}
                onEdit={onEditDealer}
                onDelete={onDeleteDealer}
                />
            <CreateDealerModal
                show={state.showCreateModal}
                onHide={onHideCreateModal}
                />
            <EditDealerModal
                show={state.showEditModal}
                dealerPrimaryKeyId={state.editDealerPrimaryKeyId}
                onHide={onHideEditModal}
                />
        </Stack>
    );
};

export default Dealers;