export enum CreateDealerAction {
    CHANGE_DEALER_ID = 'CHANGE_DEALER_ID',
    CHANGE_DEALER_NAME = 'CHANGE_DEALER_NAME',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_RESPONSE = 'RECEIVE_RESPONSE',
};

export type CreateDealerActionType = IChangeDealerNameAction
    | IChangeDealerIdAction
    | ISubmitFormAction
    | IReceiveResponseAction;

export interface IChangeDealerNameAction {
    type: CreateDealerAction.CHANGE_DEALER_NAME,
    dealerName: string,
};

export interface IChangeDealerIdAction {
    type: CreateDealerAction.CHANGE_DEALER_ID,
    dealerId: string,
};

export interface ISubmitFormAction {
    type: CreateDealerAction.SUBMIT_FORM,
};

export interface IReceiveResponseAction {
    type: CreateDealerAction.RECEIVE_RESPONSE,
};
