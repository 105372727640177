import { IMicroservice } from "../Microservices.types";

export enum EditMicroserviceAction {
    CHANGE_MICROSERVICE_NAME = 'CHANGE_MICROSERVICE_NAME',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_GET_RESPONSE = 'RECEIVE_GET_RESPONSE',
    RECEIVE_PATCH_RESPONSE = 'RECEIVE_PATCH_RESPONSE',
};

export type EditMicroserviceActionType = IChangeMicroserviceNameAction
    | ISubmitFormAction
    | IReceiveGetResponseAction
    | IReceivePatchResponseAction;

export interface IChangeMicroserviceNameAction {
    type: EditMicroserviceAction.CHANGE_MICROSERVICE_NAME,
    microserviceName: string,
};

export interface ISubmitFormAction {
    type: EditMicroserviceAction.SUBMIT_FORM,
};

export interface IReceiveGetResponseAction {
    type: EditMicroserviceAction.RECEIVE_GET_RESPONSE,
    searchResult: IMicroservice | undefined,
};

export interface IReceivePatchResponseAction {
    type: EditMicroserviceAction.RECEIVE_PATCH_RESPONSE,
}
