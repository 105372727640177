import Dealers from './Dealers';
import { IDealer } from './Dealers.types';
import { getAllDealers } from './Dealers.service';

export type {
    IDealer
};

export {
    getAllDealers,
};

export default Dealers;