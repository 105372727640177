import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { IEditMicroserviceProps, IMicroservice } from '../Microservices.types';
import { editMicroserviceReducer, initialState } from './EditMicroserviceModal.reducer';
import { getMicroserviceById, updateMicroserviceName } from './EditMicroserviceModal.service';
import { EditMicroserviceAction } from './EditMicroserviceModal.actions';

const EditMicroserviceModal = (props: IEditMicroserviceProps): JSX.Element => {
    const [state, dispatch] = React.useReducer(editMicroserviceReducer, initialState);

    React.useEffect(() => {
        if (props.microserviceId === 0) {
            return;
        }

        getMicroserviceById(props.microserviceId)
            .then((value: Response) => value.json())
            .then((searchResult: IMicroservice) => dispatch({ type: EditMicroserviceAction.RECEIVE_GET_RESPONSE, searchResult: searchResult }))
            .catch((reason: any) => dispatch({ type: EditMicroserviceAction.RECEIVE_GET_RESPONSE, searchResult: undefined }));
    }, [props.microserviceId]);

    const _updateMicroserviceName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: EditMicroserviceAction.CHANGE_MICROSERVICE_NAME, microserviceName: e.target.value });
    };

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: EditMicroserviceAction.SUBMIT_FORM });

        updateMicroserviceName(props.microserviceId, state.microserviceName)
            .then((value: Response) => dispatch({ type: EditMicroserviceAction.RECEIVE_PATCH_RESPONSE }))
            .then((value: void) => props.onHide())
            .catch((reason: any) => dispatch({ type: EditMicroserviceAction.RECEIVE_PATCH_RESPONSE }));
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop='static'
            keyboard={false}
            >
            <Modal.Header closeButton={true}>
                <Modal.Title>Edit Microservice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Stack gap={3}>
                        <Form.Group>
                            <Form.Label>Id</Form.Label>
                            <Form.Control
                                id='editMicroserviceIdReadOnlyInput'
                                type='number'
                                value={props.microserviceId}
                                disabled={true}
                                />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Microservice Name</Form.Label>
                            <Form.Control
                                id='editMicroserviceNameInput'
                                type='string'
                                placeholder='Edit Service Name'
                                value={state.microserviceName}
                                onChange={_updateMicroserviceName}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                        <Form.Group>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                >
                                Edit
                            </Button>
                        </Form.Group>
                        </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditMicroserviceModal;