import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { ICreateVendorProps } from '../Vendors.types';
import { createVendorReducer, initialState } from './CreateVendorModal.reducer';
import { CreateVendorAction } from './CreateVendorModal.actions';
import { createVendor } from './CreateVendorModal.service';

const CreateVendorModal = (props: ICreateVendorProps): JSX.Element => {
    const [state, dispatch] = React.useReducer(createVendorReducer, initialState);

    const updateVendorId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: CreateVendorAction.CHANGE_VENDOR_ID, vendorId: e.target.value });
    };

    const updateVendorName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: CreateVendorAction.CHANGE_VENDOR_NAME, vendorName: e.target.value });
    };

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: CreateVendorAction.SUBMIT_FORM });
        createVendor(state.vendorId, state.vendorName)
            .then((value: Response) => dispatch({ type: CreateVendorAction.RECEIVE_RESPONSE }))
            .then((value: void) => props.onHide())
            .catch((reason: any) => dispatch({ type: CreateVendorAction.RECEIVE_RESPONSE }));
        props.onHide();
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop='static'
            keyboard={false}
            >
            <Modal.Header closeButton={true}>
                <Modal.Title>Create Vendor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Stack gap={3}>
                            <Form.Group>
                                <Form.Label>Vendor Id</Form.Label>
                                <Form.Control
                                    id='createVendorIdInput'
                                    type='string'
                                    placeholder='Create Service Id'
                                    value={state.vendorId}
                                    onChange={updateVendorId}
                                    disabled={state.disabled}
                                    />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Vendor Name</Form.Label>
                                <Form.Control
                                    id='createVendorNameInput'
                                    type='string'
                                    placeholder='Create Service Name'
                                    value={state.vendorName}
                                    onChange={updateVendorName}
                                    disabled={state.disabled}
                                    />
                            </Form.Group>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                >
                                Create
                            </Button>
                        </Stack>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateVendorModal;