import VendorDealerLocations from "./VendorDealerLocations";
import { getAllVendorDealerLocations } from "./VendorDealerLocations.service";
import { IVendorDealerLocation } from "./VendorDealerLocations.types";

export type {
    IVendorDealerLocation,
};

export {
    getAllVendorDealerLocations,
};

export default VendorDealerLocations;