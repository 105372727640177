import React from "react";
import { ApiAccessAction, ApiAccessActionType } from "./ApiAccess.actions";
import { IApiAccess } from "./ApiAccess.types";

const { REACT_APP_BASE_URL } = process.env;

export const getAllApiAccessRecords = (): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}apiaccess/getallapiaccessrecords`);
};

export const getApiAccessByMicroserviceId = (microserviceId: number): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}apiaccess/getrecordsbymicroserviceid?microserviceId=${microserviceId}`);
};

export const getApiAccessByMicroserviceName = (microserviceName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}apiaccess/searchrecordsbymicroservicename?nameSubstring=${microserviceName}`);
};

export const getApiAccessByVendorId = (vendorId: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}apiaccess/getrecordsbyvendorid?vendorId=${vendorId}`);
};

export const getApiAccessByVendorName = (vendorName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}apiaccess/searchrecordsbyvendorname?nameSubstring=${vendorName}`);
};

export const getApiAccessByDealerId = (dealerId: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}apiaccess/getrecordsbydealerid?dealerId=${dealerId}`);
};

export const getApiAccessByDealerName = (dealerName: string): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}apiaccess/searchrecordsbydealername?nameSubstring=${dealerName}`);
};

export const deleteApiAccessById = (apiAccessId: number): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}apiaccess/deleteapiaccessbyid?apiAccessId=${apiAccessId}`, {
        method: 'DELETE',
    });
};