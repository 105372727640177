import Vendors from "./Vendors";
import { getAllVendors } from "./Vendors.service";
import { IVendor } from './Vendors.types';

export type {
    IVendor,
};

export {
    getAllVendors,
}

export default Vendors;