import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { ICreateDealerLocationProps } from '../DealerLocations.types';
import { createDealerLocationReducer, initialState } from './CreateDealerLocationModal.reducer';
import { CreateDealerLocationAction } from './CreateDealerLocationModal.actions';
import { createDealerLocation } from './CreateDealerLocationModal.service';
import { IDealer } from '../../Dealers';
import { getAllDealers } from '../../Dealers';

const CreateDealerLocationModal = (props: ICreateDealerLocationProps): JSX.Element => {
    const [state, dispatch] = React.useReducer(createDealerLocationReducer, initialState);

    React.useEffect(() => {
        getAllDealers()
            .then((value: Response) => value.json())
            .then((searchResult: IDealer[]) => dispatch({ type: CreateDealerLocationAction.RECEIVE_DEALERS_RESPONSE, dealerOptions: searchResult}))
            .catch((reason: any) => dispatch({ type: CreateDealerLocationAction.RECEIVE_DEALERS_RESPONSE, dealerOptions: [] }));
    }, []);

    const updateSelectedDealerId = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        var newSelectedDealerPrimaryKeyId = parseInt(e.target.value);
        
        dispatch({ type: CreateDealerLocationAction.SELECT_DEALER, dealerPrimaryKeyId: newSelectedDealerPrimaryKeyId });
    };

    const updateDealerLocationId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        var newLocationId = parseInt(e.target.value);

        dispatch({ type: CreateDealerLocationAction.CHANGE_LOCATION_ID, locationId: newLocationId });
    }

    const updateDealerLocationName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: CreateDealerLocationAction.CHANGE_DEALER_LOCATION_NAME, dealerLocationName: e.target.value });
    };

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: CreateDealerLocationAction.SUBMIT_FORM });
        createDealerLocation(state.selectedDealerPrimaryKeyId, state.locationId, state.dealerLocationName)
            .then((value: Response) => dispatch({ type: CreateDealerLocationAction.RECEIVE_RESPONSE }))
            .then((value: void) => props.onHide())
            .catch((reason: any) => dispatch({ type: CreateDealerLocationAction.RECEIVE_RESPONSE }));
        props.onHide();
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop='static'
            keyboard={false}
            >
            <Modal.Header closeButton={true}>
                <Modal.Title>Create DealerLocation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Stack gap={3}>
                        <Form.Group>
                            <Form.Label>Dealer</Form.Label>
                            <Form.Select
                                id='createDealerLocationDealerSelect'
                                onChange={updateSelectedDealerId}
                                disabled={state.disabled}
                                value={state.selectedDealerPrimaryKeyId}
                                >
                                {state.dealerOptions && state.dealerOptions.map((value: IDealer, index: number) => {
                                    return(
                                        <option
                                            key={`dealerOption-${index}`}
                                            value={`${value.id}`}
                                            >
                                            {value.dealerName}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Location Id</Form.Label>
                            <Form.Control
                                id='createDealerLocationIdInput'
                                type='number'
                                placeholder='Create Location Id'
                                value={state.locationId}
                                onChange={updateDealerLocationId}
                                disabled={state.disabled}
                                min={1}
                                />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Location Name</Form.Label>
                            <Form.Control
                                id='createDealerLocationNameInput'
                                type='string'
                                placeholder='Create Location Name'
                                value={state.dealerLocationName}
                                onChange={updateDealerLocationName}
                                disabled={state.disabled}
                                />
                        </Form.Group>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                >
                                Create New Location
                            </Button>
                        </Stack>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateDealerLocationModal;