import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Dealers from './Components/Dealers';
import DealerLocations from './Components/DealerLocations';
import Vendors from './Components/Vendors';
import Microservices from './Components/Microservices';
import ApiAccess from './Components/ApiAccess';
import UserPools from './Components/UserPools';
import VendorDealerLocations from './Components/VendorDealerLocations';
import UserPoolClients, { UserPoolClientDetails, UserPoolClientsLookup } from './Components/UserPoolClients';
import Landing from './Landing';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'home', element: <Landing /> },
      { path: 'dealer', element: <Dealers /> },
      { path: 'location', element: <DealerLocations /> },
      { path: 'vendor', element: <Vendors /> },
      { path: 'association', element: <VendorDealerLocations /> },
      { path: 'microservice', element: <Microservices /> },
      { path: 'access', element: <ApiAccess /> },
      { path: 'userpools', element: <UserPools /> },
      {
        path: 'userpoolclients',
        element: <UserPoolClients />,
        children: [
          { path: 'details', element: <UserPoolClientDetails /> },
          { path: 'lookup', element: <UserPoolClientsLookup /> },
        ]
      },
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
