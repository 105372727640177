import { EditApiAccessAction, EditApiAccessActionType } from "./EditApiAccessModal.actions";

export interface IEditApiAccessState {
    microserviceName: string,
    vendorDealerLocationName: string,
    canRead: boolean,
    canWrite: boolean,
    disabled: boolean,
};

export const initialState: IEditApiAccessState = {
    microserviceName: '',
    vendorDealerLocationName: '',
    canRead: false,
    canWrite: false,
    disabled: false,
};

export const editApiAccessReducer = (state: IEditApiAccessState, action: EditApiAccessActionType): IEditApiAccessState => {
    switch (action.type) {
        case EditApiAccessAction.CHANGE_CAN_READ:
            return {
                ...state,
                canRead: action.canRead,
            };
        case EditApiAccessAction.CHANGE_CAN_WRITE:
            return {
                ...state,
                canWrite: action.canWrite,
            };
        case EditApiAccessAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case EditApiAccessAction.RECEIVE_GET_RESPONSE:
            return {
                ...state,
                disabled: false,
                microserviceName: action.searchResult?.microserviceName || '',
                vendorDealerLocationName: (action.searchResult && `${action.searchResult.vendorName} <-> ${action.searchResult.dealerName} - ${action.searchResult.locationName}`) || '',
                canRead: action.searchResult?.canRead || false,
                canWrite: action.searchResult?.canWrite || false,
            };
        case EditApiAccessAction.RECEIVE_PATCH_RESPONSE:
            return {
                ...state,
                disabled: false,
            };
        default:
            throw new Error("Invalid Action type.");
    }
};
