import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import { ICreateApiAccessProps } from "../ApiAccess.types";
import { createApiAcessReducer, initialState } from './CreateApiAccessModal.reducer';
import { CreateApiAccessAction } from './CreateApiAccessModal.actions';
import { IMicroservice, getAllMicroservices} from '../../Microservices';
import { createApiAccess } from './CreateApiAccessModal.service';
import { getAllVendorDealerLocations, IVendorDealerLocation } from '../../VendorDealerLocations';

const CreateApiAccessModal = (props: ICreateApiAccessProps): JSX.Element => {
    const [state, dispatch] = React.useReducer(createApiAcessReducer, initialState);

    React.useEffect(() => {
        getAllMicroservices()
            .then((value: Response) => value.json())
            .then((searchResult: IMicroservice[]) => dispatch({ type: CreateApiAccessAction.RECEIVE_MICROSERVICES_RESPONSE, microserviceOptions: searchResult}))
            .catch((reason: any) => dispatch({ type: CreateApiAccessAction.RECEIVE_MICROSERVICES_RESPONSE, microserviceOptions: [] }));
    }, []);

    React.useEffect(() => {
        getAllVendorDealerLocations()
            .then((value: Response) => value.json())
            .then((searchResult: IVendorDealerLocation[]) => dispatch({ type: CreateApiAccessAction.RECEIVE_VENDOR_DEALER_LOCATIONS_RESPONSE, vendorDealerLocationOptions: searchResult }))
            .catch((reason: any) => dispatch({ type: CreateApiAccessAction.RECEIVE_VENDOR_DEALER_LOCATIONS_RESPONSE, vendorDealerLocationOptions: [] }));
    }, [state.microserviceOptions]);

    const updateSelectedMicroserviceId = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        var newSelectedMicroserviceId = parseInt(e.target.value);

        dispatch({ type: CreateApiAccessAction.SELECT_MICROSERVICE, selectedMicroserviceId: newSelectedMicroserviceId });
    };

    const updateSelectedVendorDealerLocationId = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        var newSelectedVendorDealerLocationId = parseInt(e.target.value);

        dispatch({ type: CreateApiAccessAction.SELECT_VENDOR_DEALER_LOCATION, selectedVendorDealerLocationId: newSelectedVendorDealerLocationId });
    };

    const submitForm = (e: React.FormEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch({ type: CreateApiAccessAction.SUBMIT_FORM });
        createApiAccess(state.selectedMicroserviceId, state.selectedVendorDealerLocationId, state.canRead, state.canWrite)
            .then((value: Response) => dispatch({ type: CreateApiAccessAction.RECEIVE_RESPONSE }))
            .then((value: void) => props.onHide())
            .catch((reason: any) => dispatch({ type: CreateApiAccessAction.RECEIVE_RESPONSE }));
    };

    const onChangeCanRead = (e: React.FormEvent<HTMLInputElement>): void => {
        var isChecked = e.currentTarget.checked;
        dispatch({ type: CreateApiAccessAction.UPDATE_CAN_READ, canRead: isChecked });
    };

    const onChangeCanWrite = (e: React.FormEvent<HTMLInputElement>): void => {
        var isChecked = e.currentTarget.checked;
        dispatch({ type: CreateApiAccessAction.UPDATE_CAN_WRITE, canWrite: isChecked });
    };
    
    return(
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop='static'
            keyboard={false}
            >
            <Modal.Header closeButton={true}>
                <Modal.Title>Create Association</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Stack gap={3}>
                            <Form.Group>
                                <Form.Label>Microservice</Form.Label>
                                <Form.Select
                                    id='createApiAcessMicroserviceSelect'
                                    onChange={updateSelectedMicroserviceId}
                                    disabled={state.disabled}
                                    value={state.selectedMicroserviceId}
                                    >
                                    {state.microserviceOptions && state.microserviceOptions.map((value: IMicroservice, index: number) => {
                                        return(
                                            <option
                                                key={`vendorOption-${index}`}
                                                value={`${value.id}`}
                                                >
                                                {value.microserviceName}
                                            </option>
                                        );
                                    })}
                                    </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Association</Form.Label>
                                <Form.Select
                                    id='createApiAcessDealerSelect'
                                    onChange={updateSelectedVendorDealerLocationId}
                                    disabled={state.disabled}
                                    value={state.selectedVendorDealerLocationId}
                                    >
                                    {state.vendorDealerLocationOptions && state.vendorDealerLocationOptions.map((value: IVendorDealerLocation, index: number) => {
                                        return(
                                            <option
                                                key={`vendorDealerLocation-${index}`}
                                                value={`${value.id}`}
                                                >
                                                {`${value.vendorName} <-> ${value.dealerName} - ${value.locationName}`}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Can Read</Form.Label>
                                <Form.Check
                                    type='checkbox'
                                    id='createApiAccessCanReadCheckbox'
                                    onChange={onChangeCanRead}
                                    checked={state.canRead}
                                    />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Can Write</Form.Label>
                                <Form.Check
                                    type='checkbox'
                                    id='createApiAccessCanWriteCheckbox'
                                    onChange={onChangeCanWrite}
                                    checked={state.canWrite}
                                    />
                            </Form.Group>
                            <Button
                                variant='primary'
                                type='submit'
                                onClick={submitForm}
                                >
                                Create New Location
                            </Button>
                        </Stack>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateApiAccessModal;