export enum CreateMicroserviceAction {
    CHANGE_MICROSERVICE_NAME = 'CHANGE_MICROSERVICE_NAME',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_RESPONSE = 'RECEIVE_RESPONSE',
};

export type CreateMicroserviceActionType = IChangeMicroserviceNameAction
    | ISubmitFormAction
    | IReceiveResponseAction;

export interface IChangeMicroserviceNameAction {
    type: CreateMicroserviceAction.CHANGE_MICROSERVICE_NAME,
    microserviceName: string,
};

export interface ISubmitFormAction {
    type: CreateMicroserviceAction.SUBMIT_FORM,
};

export interface IReceiveResponseAction {
    type: CreateMicroserviceAction.RECEIVE_RESPONSE,
}
