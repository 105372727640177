import { IVendorDealerLocation } from "./VendorDealerLocations.types";

export enum VendorDealerLocationAction {
    CHANGE_VENDOR_DEALER_LOCATION_VENDOR_ID = 'CHANGE_VENDOR_DEALER_LOCATION_VENDOR_ID',
    CHANGE_VENDOR_DEALER_LOCATION_VENDOR_NAME = 'CHANGE_VENDOR_DEALER_LOCATION_VENDOR_NAME',
    CHANGE_VENDOR_DEALER_LOCATION_DEALER_ID = 'CHANGE_VENDOR_DEALER_LOCATION_DEALER_ID',
    CHANGE_VENDOR_DEALER_LOCATION_DEALER_NAME = 'CHANGE_VENDOR_DEALER_LOCATION_DEALER_NAME',
    CHANGE_VENDOR_DEALER_LOCATION_SEARCH_CHOICE = 'CHANGE_VENDOR_DEALER_LOCATION_SEARCH_CHOICE',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_RESPONSE = 'RECEIVE_RESPONSE',
    CREATE_VENDOR_DEALER_LOCATION = 'CREATE_VENDOR_DEALER_LOCATION',
    DELETE_VENDOR_DEALER_LOCATION = 'DELETE_VENDOR_DEALER_LOCATION',
    CLOSE_CREATE_MODAL = 'CLOSE_CREATE_MODAL',
    CLOSE_EDIT_MODAL = 'CLOSE_EDIT_MODAL',
    SAVE_EDIT = 'SAVE_EDIT',
};

export enum VendorDealerLocationSearchChoice {
    SEARCH_BY_VENDOR_ID = 'SEARCH_BY_VENDOR_ID',
    SEARCH_BY_VENDOR_NAME = 'SEARCH_BY_VENDOR_NAME',
    SEARCH_BY_DEALER_ID = 'SEARCH_BY_DEALER_ID',
    SEARCH_BY_DEALER_NAME = 'SEARCH_BY_DEALER_NAME',
};

export type VendorDealerLocationActionType = IChangeVendorDealerLocationVendorIdAction
    | IChangeVendorDealerLocationVendorNameAction
    | IChangeVendorDealerLocationDealerIdAction
    | IChangeVendorDealerLocationDealerNameAction
    | IChangeDealerLocationSearchAction
    | ISubmitFormAction
    | IReceiveResponseAction
    | ICreateVendorDealerLocationAction
    | IDeleteVendorDealerLocationAction
    | ICloseCreateModalAction
    | ICloseEditModalAction
    | ISaveEditAction;

export interface IChangeVendorDealerLocationVendorIdAction {
    type: VendorDealerLocationAction.CHANGE_VENDOR_DEALER_LOCATION_VENDOR_ID,
    vendorId: string,
};

export interface IChangeVendorDealerLocationVendorNameAction {
    type: VendorDealerLocationAction.CHANGE_VENDOR_DEALER_LOCATION_VENDOR_NAME,
    vendorName: string,
};

export interface IChangeVendorDealerLocationDealerIdAction {
    type: VendorDealerLocationAction.CHANGE_VENDOR_DEALER_LOCATION_DEALER_ID,
    dealerId: string,
};

export interface IChangeVendorDealerLocationDealerNameAction {
    type: VendorDealerLocationAction.CHANGE_VENDOR_DEALER_LOCATION_DEALER_NAME,
    dealerName: string,
};

export interface IChangeDealerLocationSearchAction {
    type: VendorDealerLocationAction.CHANGE_VENDOR_DEALER_LOCATION_SEARCH_CHOICE,
    searchType: VendorDealerLocationSearchChoice,
};

export interface ISubmitFormAction {
    type: VendorDealerLocationAction.SUBMIT_FORM,
};

export interface IReceiveResponseAction {
    type: VendorDealerLocationAction.RECEIVE_RESPONSE,
    searchResult: IVendorDealerLocation[],
};

export interface ICreateVendorDealerLocationAction {
    type: VendorDealerLocationAction.CREATE_VENDOR_DEALER_LOCATION,
};

export interface IDeleteVendorDealerLocationAction {
    type: VendorDealerLocationAction.DELETE_VENDOR_DEALER_LOCATION,
};

export interface ICloseCreateModalAction {
    type: VendorDealerLocationAction.CLOSE_CREATE_MODAL,
};

export interface ICloseEditModalAction {
    type: VendorDealerLocationAction.CLOSE_EDIT_MODAL,
};

export interface ISaveEditAction {
    type: VendorDealerLocationAction.SAVE_EDIT,
};
