const { REACT_APP_BASE_URL } = process.env;

export const getApiAccessById = (apiAccessId: number): Promise<Response> => {
    return fetch(`${REACT_APP_BASE_URL}apiaccess/getapiaccessbyid?apiAccessId=${apiAccessId}`);
};

export const updateApiAccess = (apiAccessId: number, canRead: boolean, canWrite: boolean) => {
    return fetch(`${REACT_APP_BASE_URL}apiaccess/updateapiaccesspermission?apiAccessId=${apiAccessId}`, {
        method: 'PATCH',
        body: JSON.stringify({
            canRead: canRead,
            canWrite: canWrite,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    })
}