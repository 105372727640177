export enum CreateVendorAction {
    CHANGE_VENDOR_ID = 'CHANGE_VENDOR_ID',
    CHANGE_VENDOR_NAME = 'CHANGE_VENDOR_NAME',
    SUBMIT_FORM = 'SUBMIT_FORM',
    RECEIVE_RESPONSE = 'RECEIVE_RESPONSE',
};

export type CreateVendorActionType = IChangeVendorNameAction
    | IChangeVendorIdAction
    | ISubmitFormAction
    | IReceiveResponseAction;

export interface IChangeVendorNameAction {
    type: CreateVendorAction.CHANGE_VENDOR_NAME,
    vendorName: string,
};

export interface IChangeVendorIdAction {
    type: CreateVendorAction.CHANGE_VENDOR_ID,
    vendorId: string,
};

export interface ISubmitFormAction {
    type: CreateVendorAction.SUBMIT_FORM,
};

export interface IReceiveResponseAction {
    type: CreateVendorAction.RECEIVE_RESPONSE,
};
