import { CreateVendorAction, CreateVendorActionType } from "./CreateVendorModal.actions";

export interface ICreateVendorState {
    vendorId: string,
    vendorName: string,
    disabled: boolean,
};

export const initialState: ICreateVendorState = {
    vendorId: '',
    vendorName: '',
    disabled: false,
};

export const createVendorReducer = (state: ICreateVendorState, action: CreateVendorActionType): ICreateVendorState => {
    switch (action.type) {
        case CreateVendorAction.CHANGE_VENDOR_NAME:
            return {
                ...state,
                vendorName: action.vendorName,
            };
        case CreateVendorAction.CHANGE_VENDOR_ID:
            return {
                ...state,
                vendorId: action.vendorId,
            };
        case CreateVendorAction.SUBMIT_FORM:
            return {
                ...state,
                disabled: true,
            };
        case CreateVendorAction.RECEIVE_RESPONSE:
            return {
                ...state,
                disabled: false,
            };
        default:
            throw new Error("Invalid Action type.");
    }
};
